import React, {useState, useEffect} from 'react';
import Application from '../config/application';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { CallNowPopupProps } from '../model';

const Transition = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction="down" ref={ref} {...props} />
));

const CALL_NOW_INTERACTED_COOKIE = 'call_now_popup_interacted';

const CallNowPopup = React.memo(({popupProps}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setInterval(() => {
      if (!Cookies.get(CALL_NOW_INTERACTED_COOKIE)) {
        setShow(true);
      }
    }, popupProps.timeToPop * 1000)
  }, [popupProps]);

  const onPopupInteract = () => {
    Cookies.set(CALL_NOW_INTERACTED_COOKIE, 1, {expires: 1});
    setShow(false);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={show}
      maxWidth="md">
      <div id="cookiePopup">
        <div class="row" style={{backgroundColor: '#eee'}}>
          {' '}
          <span
            class="deleteMeetingClose"
            id="acceptCookie"
            onClick={() => onPopupInteract(false)}>
            &times;
          </span>
          <div class="col-lg-4 d-none d-sm-block">
            {' '}
            <img
              src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/Calling-Banner.png`}
              class="img-responsive"
              alt="telephone"
            />{' '}
          </div>
          <div class="col-lg-8" style={{textAlign: 'center'}}>
            <p
              class="h5 text-light py-1 d-none d-sm-block"
              style={{backgroundColor: '#0071cc'}}>
              Save <b>up to ${popupProps.savePrice}</b> OFF{' '}
              <span style={{marginLeft: '50px'}}>
                {' '}
                USE Discount ID :<b> CALLNOW</b>
              </span>
            </p>
            <p class="h2">{popupProps.headingText}</p>
            <img
              src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/call-center-header-007.png`}
              alt="Call center"
            />
            <div class="left">
              {' '}
              <a href={`tel:${popupProps.phone}`}>
                <p class="h4">
                  Our travel experts might have better deals for you
                </p>
              </a>
              <p class="h2">
                {' '}
                <a href={`tel:${popupProps.phone}`}>
                  {' '}
                  <b>{popupProps.phone}</b>
                </a>
              </p>
            </div>
          </div>
          <a
            class="btn btn-primary btn-outline btn-lg"
            style={{width: '100%', borderRadius: '0px'}}
            href={`tel:${popupProps.phone}`}>
            Call Now{' '}
          </a>
        </div>
      </div>
    </Dialog>
  );
});

CallNowPopup.prototype = {
  popupProps: PropTypes.instanceOf(CallNowPopupProps).isRequired
};

export default CallNowPopup;
