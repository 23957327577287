import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useHistory, useLocation, Link as RouterLink} from 'react-router-dom';
import isPostalCode from 'validator/lib/isPostalCode';
import isCreditCard from 'validator/lib/isCreditCard';
import getMonth from 'date-fns/getMonth';
import Footer from './components/Footer';
import {
  composePageTitle,
  fromJson,
  getHomeLocation,
  getLocation,
} from './Common';
import useSnackbarAlert from './hooks/useSnackbarAlert';
import {Endpoints, Months, PageUrl} from './Constants';
import range from 'lodash-es/range';
import axios from 'axios';
import clsx from 'clsx';
import {roundFormatMoney, titleCaseName} from './Util';
import Header from './components/Header';
import CountrySelect from './components/CountrySelect';
import {BookingResult} from './model';

const StateKeys = {
  COUNTRY: 'country',
  STATE: 'state',
  ADDRESS: 'address',
  CITY: 'city',
  ZIP: 'zip',
  CC: 'creditCard',
  MONTH: 'expiryMonth',
  YEAR: 'expiryYear',
  CVV: 'cvv',
  NAME: 'cardholderName',
};

const INITIAL_STATE = {
  [StateKeys.COUNTRY]: '',
  [StateKeys.STATE]: '',
  [StateKeys.ADDRESS]: '',
  [StateKeys.CITY]: '',
  [StateKeys.ZIP]: '',
  [StateKeys.CC]: '',
  [StateKeys.MONTH]: '',
  [StateKeys.YEAR]: '',
  [StateKeys.CVV]: '',
  [StateKeys.NAME]: '',
};

const Payment = () => {
  const history = useHistory();
  const location = useLocation();

  const sessionId = location.state?.sessionId;
  const bookingId = location.state?.bookingId;
  const fare = location.state?.fare;

  const [state, setState] = useState({...INITIAL_STATE});

  const [error, setError] = useState({...INITIAL_STATE});

  const [submitted, setSubmitted] = useState(false);

  const [, snackbarAlert, setSnackbarAlertError] = useSnackbarAlert();

  const currYear = new Date().getFullYear();

  useEffect(() => {
    if (!(bookingId && fare && sessionId)) {
      history.push(getLocation(PageUrl.HOME));
    }
  }, [bookingId, history, fare, sessionId]);

  const onChange = (e, key) => {
    const {value} = e.target;
    setState((s) => ({
      ...s,
      [key]: value,
    }));
    if (error[key]) {
      setError((e) => ({
        ...e,
        [key]: '',
      }));
    }
  };

  const handleSubmit = async () => {
    const errors = {};
    Object.keys(state).forEach((k) => {
      if (!state[k]) {
        errors[k] = 'Required';
      }
    });

    if (Object.keys(errors).length) {
      setError((e) => ({...e, ...errors}));
      setSnackbarAlertError(
        "Some details are missing and highlighted in 'Red'. Please fix them before proceeding."
      );
      return;
    }

    // Specific validations

    const snackbarErrors = [];

    if (!isPostalCode(state[StateKeys.ZIP], 'any')) {
      errors[StateKeys.ZIP] = 'Invalid';
      snackbarErrors.push('Zip code looks invalid.');
    }
    if (!isCreditCard(state[StateKeys.CC])) {
      errors[StateKeys.CC] = 'Invalid';
      snackbarErrors.push('Credit card looks invalid.');
    }
    if (!/^\d{3,4}$/.test(state[StateKeys.CVV])) {
      errors[StateKeys.CVV] = 'Invalid';
      snackbarErrors.push('CVV looks invalid.');
    }
    if (
      parseInt(state[StateKeys.YEAR], 10) === currYear &&
      parseInt(state[StateKeys.MONTH], 10) < getMonth(new Date()) + 1
    ) {
      errors[StateKeys.MONTH] = 'Invalid';
      snackbarErrors.push('Looks like your card has been expired.');
    }

    if (Object.keys(errors).length) {
      setError((e) => ({...e, ...errors}));
      setSnackbarAlertError(snackbarErrors.join(', '));
      return;
    }

    setSnackbarAlertError(null);
    setSubmitted(true);

    const customerCard = {
      country: state[StateKeys.COUNTRY],
      state: state[StateKeys.STATE],
      address: state[StateKeys.ADDRESS],
      city: state[StateKeys.CITY],
      postal: state[StateKeys.ZIP],
      cardNumber: state[StateKeys.CC],
      expiryMonth: state[StateKeys.MONTH],
      expiryYear: state[StateKeys.YEAR],
      cvv: state[StateKeys.CVV],
      name: titleCaseName(state[StateKeys.NAME]),
    };

    // proceed to booking
    const {data} = await axios.post(Endpoints.FLIGHT_BOOK, {
      sessionId,
      bookingId,
      customerCard,
    });
    const bookingResult = fromJson(BookingResult, data);

    if (!bookingResult.partiallyBooked && !bookingResult.chargeSuccess) {
      setSnackbarAlertError(
        `Sorry but your payment was failed with error: ${bookingResult.paymentError}.` +
          'Please check card details and try again.'
      );
      setSubmitted(false);
      return;
    }

    // TODO: work on payment errors later
    history.push(
      getLocation(PageUrl.THANK_YOU, null, {
        fare,
        location,
        customerCard,
        bookingResult: bookingResult,
      })
    );
  };

  if (!(bookingId && fare)) {
    return;
  }

  return (
    <>
      <div id="main-wrapper">
        <Helmet title={composePageTitle('Payment')} />
        <Header />
        <section className="page-header page-header-text-light bg-secondary">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h1>Payment</h1>
              </div>
              <div className="col-md-4">
                <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                  <li>
                    <RouterLink to={getHomeLocation(location)}>Home</RouterLink>
                  </li>
                  <li className="active">Payment</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div id="content">
          <div className="container">
            <div className="bg-light shadow-md rounded">
              <div className="row align-items-center p-4">
                <div className="col-md-6">
                  <h2 className="text-primary d-flex align-items-center m-0">
                    <span className="text-3 text-dark mr-1">
                      Total Payable Amount:{' '}
                    </span>{' '}
                    {roundFormatMoney(fare.baseFare + fare.totalTax)}
                  </h2>
                </div>
              </div>
              <hr className="m-0" style={{borderColor: '#000'}} />
              <div className="p-4">
                <div className="tab-content" id="myTabContentVertical">
                  <div
                    className="tab-pane fade show active"
                    id="firstTab"
                    role="tabpanel"
                    aria-labelledby="first-tab">
                    <div className="row ">
                      <div className="col-lg-6">
                        <h5 className="text-6 mb-4">
                          <b>Billing Information</b>
                        </h5>
                        <div className="mb-3" />
                        <div>
                          <CountrySelect
                            className={clsx(
                              'custom-select mb-3',
                              Boolean(error[StateKeys.COUNTRY]) && 'error'
                            )}
                            value={state[StateKeys.COUNTRY]}
                            onChange={(e) => onChange(e, StateKeys.COUNTRY)}
                          />
                        </div>
                        {state[StateKeys.COUNTRY] === 'US' ? (
                          <div>
                            <select
                              name="state"
                              id="state"
                              className={clsx(
                                'custom-select mb-3',
                                Boolean(error[StateKeys.STATE]) && 'error'
                              )}
                              value={state[StateKeys.STATE]}
                              onChange={(e) => onChange(e, StateKeys.STATE)}>
                              <option value="">Select State*</option>
                              <option value="AK">ALASKA</option>
                              <option value="AL">ALABAMA</option>
                              <option value="AR">ARKANSAS</option>
                              <option value="AZ">ARIZONA</option>
                              <option value="CA">CALIFORNIA</option>
                              <option value="CO">COLORADO</option>
                              <option value="CT">CONNECTICUT</option>
                              <option value="DC">DISTRICT OF COLUMBIA</option>
                              <option value="DE">DELAWARE</option>
                              <option value="FL">FLORIDA</option>
                              <option value="GA">GEORGIA</option>
                              <option value="HI">HAWAII</option>
                              <option value="IA">IOWA</option>
                              <option value="ID">IDAHO</option>
                              <option value="IL">ILLINOIS</option>
                              <option value="IN">INDIANA</option>
                              <option value="KS">KANSAS</option>
                              <option value="KY">KENTUCKY</option>
                              <option value="LA">LOUISIANA</option>
                              <option value="MA">MASSACHUSETTS</option>
                              <option value="MD">MARYLAND</option>
                              <option value="ME">MAINE</option>
                              <option value="MI">MICHIGAN</option>
                              <option value="MN">MINNESOTA</option>
                              <option value="MO">MISSOURI</option>
                              <option value="MS">MISSISSIPI</option>
                              <option value="MT">MONTANA</option>
                              <option value="NC">NORTH CAROLINA</option>
                              <option value="ND">NORTH DAKOTA</option>
                              <option value="NE">NEBRASKA</option>
                              <option value="NH">NEW HAMPSHIRE</option>
                              <option value="NJ">NEW JERSEY</option>
                              <option value="NM">NEW MEXICO</option>
                              <option value="NV">NEVADA</option>
                              <option value="NY">NEW YORK</option>
                              <option value="OH">OHIO</option>
                              <option value="OK">OKLAHOMA</option>
                              <option value="OR">OREGON</option>
                              <option value="PA">PENNSYLVANIA</option>
                              <option value="PR">Puerto Rico Terr</option>
                              <option value="RI">RHODE ISLAND</option>
                              <option value="SC">SOUTH CAROLINA</option>
                              <option value="SD">SOUTH DAKOTA</option>
                              <option value="TN">TENNESSEE</option>
                              <option value="TX">TEXAS</option>
                              <option value="UT">UTAH</option>
                              <option value="VA">VIRGINIA</option>
                              <option value="VT">VERMONT</option>
                              <option value="WA">WASHINGTON</option>
                              <option value="WI">WISCONSIN</option>
                              <option value="WV">WEST VIRGINIA</option>
                              <option value="WY">WYOMING</option>
                              <option value="VI">VIRGIN ISLANDS TERR</option>
                            </select>
                          </div>
                        ) : state[StateKeys.COUNTRY] === 'CA' ? (
                          <div>
                            <select
                              name="state"
                              id="state"
                              className={clsx(
                                'custom-select mb-3',
                                Boolean(error[StateKeys.STATE]) && 'error'
                              )}
                              value={state[StateKeys.STATE]}
                              onChange={(e) => onChange(e, StateKeys.STATE)}>
                              <option value="">Select State*</option>
                              <option value="AB">ALBERTA</option>
                              <option value="BC">BRITISH COLUMBIA</option>
                              <option value="MB">MANITOBA</option>
                              <option value="NB">NEW BRUNSWICK</option>
                              <option value="NL">
                                NEWFOUNDLAND AND LABRADOR
                              </option>
                              <option value="NS">NOVA SCOTIA</option>
                              <option value="NT">NORTHWEST TERRITORIES</option>
                              <option value="NU">NUNAVUT</option>
                              <option value="ON">ONTARIO</option>
                              <option value="PE">PRINCE EDWARD ISLAND</option>
                              <option value="QC">QUEBEC</option>
                              <option value="SK">SASKATCHEWAN</option>
                              <option value="YT">YUKON TERRITORY</option>
                            </select>
                          </div>
                        ) : (
                          <div className="mb-3" id="otherCountry">
                            <input
                              type="text"
                              data-bv-field="number"
                              id="State"
                              placeholder="Enter State*"
                              className={clsx(
                                'form-control',
                                Boolean(error[StateKeys.STATE]) && 'error'
                              )}
                              value={state[StateKeys.STATE]}
                              onChange={(e) => onChange(e, StateKeys.STATE)}
                            />
                          </div>
                        )}
                        <div className="mb-3">
                          <input
                            type="text"
                            data-bv-field="number"
                            id="Address"
                            placeholder="Enter Address*"
                            className={clsx(
                              'form-control',
                              Boolean(error[StateKeys.ADDRESS]) && 'error'
                            )}
                            value={state[StateKeys.ADDRESS]}
                            onChange={(e) => onChange(e, StateKeys.ADDRESS)}
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            type="text"
                            id="City"
                            placeholder="Enter Your City*"
                            className={clsx(
                              'form-control',
                              Boolean(error[StateKeys.CITY]) && 'error'
                            )}
                            value={state[StateKeys.CITY]}
                            onChange={(e) => onChange(e, StateKeys.CITY)}
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            type="text"
                            data-bv-field="number"
                            id="Zip"
                            placeholder="Enter Zip*"
                            className={clsx(
                              'form-control',
                              Boolean(error[StateKeys.ZIP]) && 'error'
                            )}
                            value={state[StateKeys.ZIP]}
                            onChange={(e) => onChange(e, StateKeys.ZIP)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <h2 className="text-6">
                          <b>Enter Card Details</b>
                        </h2>
                        <span>
                          <p>We Accept:</p>
                          <ul className="payments-types mb-3">
                            <li>
                              <img
                                data-toggle="tooltip"
                                src={
                                  require('./images/payment/visa.png').default
                                }
                                alt="visa"
                                title="Visa"
                              />
                            </li>
                            <li>
                              <img
                                data-toggle="tooltip"
                                src={
                                  require('./images/payment/discover.png')
                                    .default
                                }
                                alt="discover"
                                title="Discover"
                              />
                            </li>
                            <li>
                              <img
                                data-toggle="tooltip"
                                src={
                                  require('./images/payment/american.png')
                                    .default
                                }
                                alt="american express"
                                title="American Express"
                              />
                            </li>
                            <li>
                              <img
                                data-toggle="tooltip"
                                src={
                                  require('./images/payment/mastercard.png')
                                    .default
                                }
                                alt="mastercard"
                                title="Mastercard"
                              />
                            </li>
                          </ul>
                        </span>
                        <div className="form-group">
                          <input
                            type="number"
                            data-bv-field="cardnumber"
                            id="cardNumber"
                            placeholder="Card Number"
                            className={clsx(
                              'form-control',
                              Boolean(error[StateKeys.CC]) && 'error'
                            )}
                            value={state[StateKeys.CC]}
                            onChange={(e) => onChange(e, StateKeys.CC)}
                          />
                        </div>
                        <div className="form-row">
                          <div className="col-lg-4">
                            <div className="form-group">
                              <select
                                className={clsx(
                                  'custom-select',
                                  Boolean(error[StateKeys.MONTH]) && 'error'
                                )}
                                value={state[StateKeys.MONTH]}
                                onChange={(e) => onChange(e, StateKeys.MONTH)}>
                                <option value="">Expiry Month</option>
                                {Months.map((m, i) => (
                                  <option value={i + 1} key={i}>
                                    {m}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <select
                                className={clsx(
                                  'custom-select',
                                  Boolean(error[StateKeys.YEAR]) && 'error'
                                )}
                                value={state[StateKeys.YEAR]}
                                onChange={(e) => onChange(e, StateKeys.YEAR)}>
                                <option value="">Expiry Year</option>
                                {range(currYear, currYear + 10).map((i) => (
                                  <option value={i} key={i}>
                                    {i}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <input
                                type="number"
                                data-bv-field="cvvnumber"
                                id="cvvNumber"
                                placeholder="CVV Number"
                                className={clsx(
                                  'form-control',
                                  Boolean(error[StateKeys.CVV]) && 'error'
                                )}
                                value={state[StateKeys.CVV]}
                                onChange={(e) => onChange(e, StateKeys.CVV)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            data-bv-field="cardholdername"
                            id="cardHolderName"
                            placeholder="Card Holder Name"
                            className={clsx(
                              'form-control',
                              Boolean(error[StateKeys.NAME]) && 'error'
                            )}
                            value={state[StateKeys.NAME]}
                            onChange={(e) => onChange(e, StateKeys.NAME)}
                          />
                        </div>
                        <button
                          className="btn btn-primary"
                          onClick={handleSubmit}
                          disabled={submitted}>
                          {submitted ? 'Processing...' : 'Pay & Finish Booking'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {snackbarAlert}
    </>
  );
};

export default Payment;
