import React, {useEffect, useMemo, useRef, useState} from 'react';
import queryString from 'query-string';
import {useQuery} from 'react-query';
import {makeStyles} from '@material-ui/core/styles';
import {ButtonBase} from '@material-ui/core';
import defaultsDeep from 'lodash-es/defaultsDeep';
import without from 'lodash-es/without';
import groupBy from 'lodash-es/groupBy';
import compareDateAsc from 'date-fns/compareAsc';
import Slider from '@material-ui/core/Slider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './vendor/bootstrap/css/bootstrap.min.css';
import './css/stylesheet.css';
import './css/custom.css';
import {
  DATE_FORMAT,
  FlightClass,
  JourneyType,
  ListingQParams,
  PAX_LIMIT,
  QueryKeys,
} from './Constants';
import {airlinesFetch, airportsFetch, flightSearchFetch} from './api/fetches';
import {
  formatDate,
  getTimeFromDayMins,
  getTotalMinsInDay,
  parseDate,
  roundFormatMoney,
  roundNum,
  tryParseDateOrNull,
  tryParseNumOr0,
  tryParseNumOrNull,
} from './Util';
import {useHistory, useLocation} from 'react-router-dom';
import FlightOverview from './components/FlightOverview';
import {
  composePageTitle,
  getAirline,
  getAirport,
  sendHome,
  tryGetAirport,
} from './Common';
import Header from './components/Header';
import ListingSearch from './components/ListingSearch';
import Footer from './components/Footer';
import clsx from 'clsx';
import {Helmet} from 'react-helmet-async';
import {isBefore, isSameDay} from 'date-fns';

const useStyles = makeStyles((theme) => ({
  buttonBaseRoot: {
    color: '#0071cc',
  },
  sliderRoot: {
    color: '#0071cc',
  },
}));

const START_RECORDS_PAGE = 20;

const OTHER_AIRLINES_CODE = '__OA__';

const INC_CODE = '_inc';

const STOP_FILTER_KEYS = {
  NS: 'NS',
  OS: 'OS',
  MS: 'MS',
};

const STOP_FILTER_DISPLAY_VALUE = {
  [STOP_FILTER_KEYS.NS]: 'Non Stop',
  [STOP_FILTER_KEYS.OS]: '1 Stop',
  [STOP_FILTER_KEYS.MS]: 'Multi Stop',
};

const isSearchDataValid = (
  jtype,
  from,
  to,
  dep,
  ret,
  adults,
  children,
  infants,
  cls,
  airports
) => {
  if (!(jtype && from && to && dep && adults && cls)) {
    return false;
  }

  if (!Object.values(JourneyType).includes(jtype)) {
    return false;
  }

  const isReturnJ = jtype === JourneyType.RETURN;

  if (isReturnJ && !ret) {
    return false;
  }

  if (!(tryGetAirport(airports, from) && tryGetAirport(airports, to))) {
    return false;
  }

  const depDate = tryParseDateOrNull(dep);
  const today = new Date();

  if (!depDate || (isBefore(depDate, today) && !isSameDay(depDate, today))) {
    return false;
  }

  if (isReturnJ) {
    const retDate = tryParseDateOrNull(ret);
    if (
      !retDate ||
      (isBefore(retDate, depDate) && !isSameDay(retDate, depDate))
    ) {
      return false;
    }
  }

  let totalPax = 0;

  const adultsNum = tryParseNumOr0(adults);
  totalPax += adultsNum;
  if (adultsNum <= 0) {
    return false;
  }

  if (children) {
    const childrenNum = tryParseNumOrNull(children);
    totalPax += childrenNum;
    if (childrenNum === null || childrenNum < 0) {
      return false;
    }
  }

  if (infants) {
    const infNum = tryParseNumOrNull(infants);
    totalPax += infNum;
    if (infNum === null || infNum < 0) {
      return false;
    }
  }

  if (totalPax > PAX_LIMIT) {
    return false;
  }

  if (!Object.values(FlightClass).includes(cls)) {
    return false;
  }

  return true;
};

const Listing = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const {data: airports} = useQuery([QueryKeys.AIRPORTS], airportsFetch, {
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const {data: airlines} = useQuery([QueryKeys.AIRLINES], airlinesFetch, {
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const parsed = useMemo(() => queryString.parse(location.search), [
    location.search,
  ]);
  // Object that will hold data for filtering/paging so that we fetch just once and filter locally.
  const filtersInitialStateRef = useRef(null);

  const jtype = parsed[ListingQParams.JOURNEY_TYPE];
  const from = parsed[ListingQParams.FROM];
  const to = parsed[ListingQParams.TO];
  const dep = parsed[ListingQParams.DEPARTURE_DATE];
  const ret = parsed[ListingQParams.RETURN_DATE];
  const adults = parsed[ListingQParams.ADULTS];
  const children = parsed[ListingQParams.CHILDREN];
  const infants = parsed[ListingQParams.INFANTS];
  const cls = parsed[ListingQParams.CLASS];
  const testing = parsed[ListingQParams.TEST];

  const depDate = dep ? new Date(dep) : null;
  const retDate = ret ? new Date(ret) : null;
  const isValidSearch = isSearchDataValid(
    jtype,
    from,
    to,
    dep,
    ret,
    adults,
    children,
    infants,
    cls,
    airports
  );

  const {data, error, isFetching, isLoading, isError} = useQuery(
    [
      QueryKeys.LISTING,
      jtype,
      from,
      to,
      dep,
      ret,
      adults,
      children,
      infants,
      cls,
      testing,
    ],
    flightSearchFetch,
    {
      staleTime: 20 * 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: isValidSearch,
    }
  );

  // Mutable 'fares' object
  const [fares, setFares] = useState(null);
  const [filters, setFilters] = useState(null);
  const [showingRecords, setShowingRecords] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [showFilters, setShowFilers] = useState(false);

  // if search params were not supplied, send back to home
  useEffect(() => {
    if (!isValidSearch) {
      sendHome(history, location);
    }
  }, [isValidSearch, history, location]);

  // Listen to data changes and set 'fares' object
  useEffect(() => {
    // Time range is in day-of-minutes such as [18 * 60 - 23 * 60]
    const getTimeRangeFromDates = (dates) => {
      dates.sort((a, b) => compareDateAsc(a, b));
      return [
        getTotalMinsInDay(dates[0]),
        getTotalMinsInDay(dates[dates.length - 1]),
      ];
    };

    // Before the very first fetching
    if (!data) {
      return;
    }

    // When there is no data
    if (data.fares.length === 0) {
      setFares(null);
      setFilters(null);
      return;
    }

    // Make a deep clone so we don't touch the 'data' object in any way
    const clonedData = defaultsDeep([], data.fares);
    // Put a prop 'INC_CODE' (include) on every fare object. This will help including/excluding a fare
    // when filters are applied.
    clonedData.forEach((d) => (d[INC_CODE] = true));
    setFares(clonedData);

    // Initialize filters data
    const filtersData = {
      stops: {},
      outboundDepTimeRange: [],
      inboundDepTimeRange: [],
      airlines: {},
      priceRange: [],
    };

    // Stops
    const stopsFilter = {};
    if (clonedData.some((d) => d.itineraries.some((i) => i.totalStops === 0))) {
      stopsFilter[STOP_FILTER_KEYS.NS] = true;
    }

    if (clonedData.some((d) => d.itineraries.some((i) => i.totalStops === 1))) {
      stopsFilter[STOP_FILTER_KEYS.OS] = true;
    }

    if (clonedData.some((d) => d.itineraries.some((i) => i.totalStops > 1))) {
      stopsFilter[STOP_FILTER_KEYS.MS] = true;
    }
    filtersData.stops = stopsFilter;

    // Outbound departure
    const outboundDepDates = clonedData.map((d) =>
      parseDate(d.itineraries[0].stops[0].departureDateTime)
    );
    filtersData.outboundDepTimeRange = getTimeRangeFromDates(outboundDepDates);

    // Inbound departure (if available)
    // Any itinerary > 1 means we've a return fare
    if (clonedData.some((d) => d.itineraries.length > 1)) {
      const inboundDepDates = clonedData.map((d) =>
        parseDate(d.itineraries[1].stops[0].departureDateTime)
      );
      filtersData.inboundDepTimeRange = getTimeRangeFromDates(inboundDepDates);
    }

    // Price
    const prices = clonedData.map((d) => d.perAdultTotalFare);
    const minPrice = prices.reduce((min, p) => Math.min(min, p));
    const maxPrice = prices.reduce((max, p) => Math.max(max, p));
    filtersData.priceRange = [roundNum(minPrice), roundNum(maxPrice)];

    // Airlines
    // We'll show top 3 airlines and an 'Other airlines'

    // Create a group like {'UA': ['UA','UA',..], 'DL': ['DL','DL',..]}
    const airlines = clonedData
      .map((d) => d.itineraries.map((i) => i.stops.map((s) => s.airlineCode)))
      .flat(2); // Flatten the array till depth = 2
    const airlineGroup = groupBy(airlines);
    // Transform to something like [{airline: 'UA', occurrence: 3}, ... in desc order of occurrence
    const airlineToOccurrenceArrayDesc = Object.keys(airlineGroup)
      .map((a) => ({
        airline: a,
        occurrence: airlineGroup[a].length,
      }))
      .sort((a, b) => b.occurrence - a.occurrence);
    // Pick the top 3 codes
    const top3AirlinesCode = airlineToOccurrenceArrayDesc
      .slice(0, 3)
      .map((o) => o.airline);

    const airlineFilter = {};
    top3AirlinesCode.forEach((a) => (airlineFilter[a] = true));
    // Show the all-other option only when we've more airlines than we're showing
    if (airlineToOccurrenceArrayDesc.length > 3) {
      airlineFilter[OTHER_AIRLINES_CODE] = true;
    }
    filtersData.airlines = airlineFilter;

    // Once initial filter state is build, assign it to the ref for storage and to the state
    filtersInitialStateRef.current = filtersData;
    setFilters(filtersData);
  }, [data]);

  // Listen to filter changes and mutate data. Filtering should take account all the current filters (only
  // filters that are not equal to the initial state).
  useEffect(() => {
    if (!filters) {
      return;
    }

    const initialFilters = filtersInitialStateRef.current;
    // During reset
    if (filters === initialFilters) {
      setFares((fares) => {
        const clonedFares = [...fares];
        clonedFares.forEach((f) => (f[INC_CODE] = true));
        return clonedFares;
      });
      return;
    }

    setFares((fares) => {
      const clonedFares = [...fares];

      // Make all the fares available so that we take into account only changed filters
      clonedFares.forEach((f) => (f[INC_CODE] = true));

      // Build predicates for all kind of filters that will be used to build a sequential condition joined
      // with 'OR' operator so that if any of them returns true, the fare is excluded from the results.

      const excludedFarePredicates = [];

      // Stops
      const stopFilterFunc = (i) => {
        const {stops} = filters;
        let result = false;
        if (!result && !stops[STOP_FILTER_KEYS.NS]) {
          result = i.totalStops === 0;
        }
        if (!result && !stops[STOP_FILTER_KEYS.OS]) {
          result = i.totalStops === 1;
        }
        if (!result && !stops[STOP_FILTER_KEYS.MS]) {
          result = i.totalStops > 1;
        }
        return result;
      };
      const {stops} = filters;
      if (Object.keys(stops).some((stopKey) => !stops[stopKey])) {
        excludedFarePredicates.push((f) => f.itineraries.every(stopFilterFunc));
      }

      // Airlines
      const {airlines} = filters;
      const airlinesInFilter = Object.keys(airlines);
      const airlineCodesWithoutOtherAirlineCode = without(
        airlinesInFilter,
        OTHER_AIRLINES_CODE
      );

      airlinesInFilter
        .filter((airlineCode) => !airlines[airlineCode]) // Only when excluded
        .forEach((airlineCode) => {
          let airlineFilterFunc = null;
          switch (airlineCode) {
            case OTHER_AIRLINES_CODE:
              // Other airlines are those that aren't the ones that are in the filters
              airlineFilterFunc = (i) =>
                i.stops.some(
                  (s) =>
                    !airlineCodesWithoutOtherAirlineCode.includes(s.airlineCode)
                );
              break;
            default:
              airlineFilterFunc = (i) =>
                i.stops.some((s) => s.airlineCode === airlineCode);
          }
          excludedFarePredicates.push((f) =>
            f.itineraries.some(airlineFilterFunc)
          );
        });

      // Outbound departure, only when changed
      const [newOutDepStart, newOutDepEnd] = filters.outboundDepTimeRange;
      const [
        initialOutDepStart,
        initialOutDepEnd,
      ] = initialFilters.outboundDepTimeRange;
      if (
        newOutDepStart !== initialOutDepStart ||
        newOutDepEnd !== initialOutDepEnd
      ) {
        excludedFarePredicates.push((f) => {
          const depTimeInDayMins = getTotalMinsInDay(
            parseDate(f.itineraries[0].stops[0].departureDateTime)
          );
          // Excluded those outside of range
          return (
            depTimeInDayMins < newOutDepStart || depTimeInDayMins > newOutDepEnd
          );
        });
      }

      // Inbound departure, only when available and changed
      if (filters.inboundDepTimeRange.length > 0) {
        const [newInDepStart, newInDepEnd] = filters.inboundDepTimeRange;
        const [
          initialInDepStart,
          initialInDepEnd,
        ] = initialFilters.inboundDepTimeRange;
        if (
          newInDepStart !== initialInDepStart ||
          newInDepEnd !== initialInDepEnd
        ) {
          excludedFarePredicates.push((f) => {
            const depTimeInDayMins = getTotalMinsInDay(
              parseDate(f.itineraries[1].stops[0].departureDateTime)
            );
            // Excluded those outside of range
            return (
              depTimeInDayMins < newInDepStart || depTimeInDayMins > newInDepEnd
            );
          });
        }
      }

      // Prices, only when changed
      const [newPriceStart, newPriceEnd] = filters.priceRange;
      const [initialPriceStart, initialPriceEnd] = initialFilters.priceRange;
      if (
        newPriceStart !== initialPriceStart ||
        newPriceEnd !== initialPriceEnd
      ) {
        excludedFarePredicates.push((f) => {
          const price = roundNum(f.perAdultTotalFare);
          // Excluded those outside of range
          return price < newPriceStart || price > newPriceEnd;
        });
      }

      if (excludedFarePredicates.length === 0) {
        return clonedFares;
      }

      clonedFares
        .filter((f) => excludedFarePredicates.some((fp) => fp(f)))
        .forEach((f) => (f[INC_CODE] = false));

      return clonedFares;
    });
  }, [filters]);

  // Listen to fare changes
  useEffect(() => {
    if (!fares) {
      setShowingRecords(0);
      return;
    }

    // Set to initial records per page whenever there is a change in fare
    setShowingRecords(START_RECORDS_PAGE);
  }, [fares]);

  const clearFilters = () => {
    setFilters(filtersInitialStateRef.current);
  };

  const onStopChange = (key) => {
    const {stops} = filters;
    const finalState = !stops[key];

    // change filters state
    setFilters((f) => ({
      ...f,
      stops: {
        ...stops,
        [key]: finalState,
      },
    }));
  };

  const onAirlineChange = (code) => {
    const {airlines} = filters;
    const finalState = !airlines[code];

    setFilters((f) => ({
      ...f,
      airlines: {
        ...airlines,
        [code]: finalState,
      },
    }));
  };

  const onOutboundDepTimeChange = (e, newValue) => {
    setFilters((f) => ({
      ...f,
      outboundDepTimeRange: newValue,
    }));
  };

  const onInboundDepTimeChange = (e, newValue) => {
    setFilters((f) => ({
      ...f,
      inboundDepTimeRange: newValue,
    }));
  };

  const onPriceChange = (e, newValue) => {
    setFilters((f) => ({
      ...f,
      priceRange: newValue,
    }));
  };

  const getNoDataBlock = (content, isError) => (
    <div
      className="center"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10% 10%',
      }}>
      <div className="text-center">
        <div className="mb-3">
          <span className={clsx('text-2 d-block', isError && 'text-danger')}>
            {content}
          </span>
        </div>
      </div>
    </div>
  );

  if (!isValidSearch) {
    return null;
  }

  return (
    <>
      <div id="main-wrapper">
        <Helmet title={composePageTitle('Listing')} />
        <Header />
        <section className="page-header page-header-text-light bg-secondary">
          <div className="container">
            <div className="row align-items-center">
              <div>
                <h1>
                  {getAirport(airports, from).city} - {getAirport(airports, to).city}
                  {' | '}
                  {formatDate(depDate, DATE_FORMAT)}
                  {jtype === JourneyType.RETURN
                    ? ` - ${formatDate(retDate, DATE_FORMAT)}`
                    : ''}
                </h1>
              </div>
            </div>
          </div>
        </section>
        {/* Page Header end. TODO: Add functionality to these so that they show/hide
        search and filter blocks on mobile. */}
        <div className="filters-search-mobile mb-4 d-lg-none">
          <div
            className="btn-group w-100"
            role="group"
            aria-label="Basic example">
            <button
              id="searchFlightOpen"
              type="button"
              className={clsx(
                'btn btn-default',
                showSearch && 'bg-primary text-white'
              )}
              onClick={() => setShowSearch((s) => !s)}>
              <i className="fa fa-plane mr-2" />
              Modify Search
            </button>
            <button
              id="filterOpen"
              type="button"
              className={clsx(
                'btn btn-default',
                showFilters && 'bg-primary text-white'
              )}
              onClick={() => setShowFilers((s) => !s)}>
              <i className="fa fa-filter mr-2" />
              Refine your search
            </button>
          </div>
        </div>

        {/* Content
============================================= */}
        <div id="content">
          <section className="container search-result">
            <ListingSearch searchText="Modify Search" showSearch={showSearch} />
            <div className="row">
              <aside
                className={clsx(
                  'col-lg-3 mb-4 mb-lg-0',
                  !showFilters && 'show-on-mobile'
                )}>
                {filters ? (
                  <div className="bg-light shadow-md rounded p-3">
                    <h3 className="text-5">Refine your search</h3>
                    <div className="accordion accordion-alternate style-2">
                      <div className="card">
                        <div className="card-header">
                          <h6 className="mb-2 mt-2">Stops</h6>
                        </div>
                        <div className="show" aria-labelledby="Stops">
                          <div className="card-body">
                            {Object.keys(filters.stops).map((s) => (
                              <div style={{paddingLeft: '0.5rem'}} key={s}>
                                <FormControl>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        style={{
                                          padding: '0px',
                                          marginRight: '5px',
                                          color: '#0071cc',
                                        }}
                                        name={s}
                                        checked={filters.stops[s]}
                                        onChange={() => onStopChange(s)}
                                        color="primary"
                                      />
                                    }
                                    label={STOP_FILTER_DISPLAY_VALUE[s]}
                                  />
                                </FormControl>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <h6 className="mb-2 mt-2">Outbound Departure Time</h6>
                        </div>
                        <div
                          className="show"
                          aria-labelledby="Outbound Departure Time">
                          <div className="card-body">
                            <input
                              type="text"
                              value={`${getTimeFromDayMins(
                                filters.outboundDepTimeRange[0]
                              )} - ${getTimeFromDayMins(
                                filters.outboundDepTimeRange[1]
                              )}`}
                              readOnly
                              className="form-control border-0 bg-transparent p-0"
                            />
                            <div style={{padding: '0.5rem 0.5rem'}}>
                              <Slider
                                value={filters.outboundDepTimeRange}
                                onChange={onOutboundDepTimeChange}
                                valueLabelDisplay="off"
                                aria-labelledby="Outbound Departure Time"
                                min={
                                  filtersInitialStateRef.current
                                    .outboundDepTimeRange[0]
                                }
                                max={
                                  filtersInitialStateRef.current
                                    .outboundDepTimeRange[1]
                                }
                                step={1}
                                classes={{root: classes.sliderRoot}}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {filters.inboundDepTimeRange.length > 0 ? (
                        <div className="card">
                          <div className="card-header">
                            <h6 className="mb-2 mt-2">
                              Inbound Departure Time
                            </h6>
                          </div>
                          <div
                            className="show"
                            aria-labelledby="Inbound Departure Time">
                            <div className="card-body">
                              <input
                                type="text"
                                value={`${getTimeFromDayMins(
                                  filters.inboundDepTimeRange[0]
                                )} - ${getTimeFromDayMins(
                                  filters.inboundDepTimeRange[1]
                                )}`}
                                readOnly
                                className="form-control border-0 bg-transparent p-0"
                              />
                              <div style={{padding: '0.5rem 0.5rem'}}>
                                <Slider
                                  value={filters.inboundDepTimeRange}
                                  onChange={onInboundDepTimeChange}
                                  valueLabelDisplay="off"
                                  aria-labelledby="Inbound Departure Time"
                                  min={
                                    filtersInitialStateRef.current
                                      .inboundDepTimeRange[0]
                                  }
                                  max={
                                    filtersInitialStateRef.current
                                      .inboundDepTimeRange[1]
                                  }
                                  step={1}
                                  classes={{root: classes.sliderRoot}}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="card">
                        <div className="card-header">
                          <h6 className="mb-2 mt-2">Per Person Price</h6>
                        </div>
                        <div
                          className="show"
                          aria-labelledby="Per Person Price">
                          <div className="card-body">
                            <input
                              type="text"
                              value={`${roundFormatMoney(
                                filters.priceRange[0]
                              )} - ${roundFormatMoney(filters.priceRange[1])}`}
                              readOnly
                              className="form-control border-0 bg-transparent p-0"
                            />
                            <div style={{padding: '0.5rem 0.5rem'}}>
                              <Slider
                                value={filters.priceRange}
                                onChange={onPriceChange}
                                valueLabelDisplay="off"
                                aria-labelledby="Per Person Price"
                                min={
                                  filtersInitialStateRef.current.priceRange[0]
                                }
                                max={
                                  filtersInitialStateRef.current.priceRange[1]
                                }
                                step={1}
                                classes={{root: classes.sliderRoot}}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <h6 className="mb-2 mt-2">Airlines</h6>
                        </div>
                        <div className="show" aria-labelledby="Airlines">
                          <div className="card-body">
                            {Object.keys(filters.airlines).map((a) => (
                              <div style={{paddingLeft: '0.5rem'}} key={a}>
                                <FormControl>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        style={{
                                          padding: '0px',
                                          marginRight: '5px',
                                          color: '#0071cc',
                                        }}
                                        name={a}
                                        checked={filters.airlines[a]}
                                        onChange={() => onAirlineChange(a)}
                                        color="primary"
                                      />
                                    }
                                    label={
                                      a === OTHER_AIRLINES_CODE
                                        ? 'Other Airlines'
                                        : getAirline(airlines, a).name
                                    }
                                  />
                                </FormControl>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <ButtonBase
                        style={{
                          padding: '16px',
                          backgroundColor: '#0071cc',
                          borderColor: '#0071cc',
                          color: '#fff',
                        }}
                        className="btn btn-primary research-flight mt-3 mt-lg-0"
                        disableRipple
                        onClick={clearFilters}>
                        Reset All Filters
                      </ButtonBase>
                    </div>
                  </div>
                ) : null}
              </aside>
              <div className="col-lg-9 mt-md-0">
                {/* Preloader */}
                {isLoading || isFetching ? (
                  <div id="preloader" className="center search-preloader">
                    <div data-loader="dual-ring">
                      <div className="preloader-icon">
                        <i className="fa fa-plane" />
                      </div>
                    </div>
                    <div className="text-center search-summary">
                      <div className="mb-3">
                        {fares
                          ? 'Refreshing results, please wait...'
                          : 'Searching for the best deals for you...'}
                      </div>
                      <div className="where-to-from-summary">
                        <span className="mr-2">{getAirport(airports, from).name}</span>
                        <span>
                          <i className="fa fa-arrows-alt-h" />
                        </span>
                        <span className="ml-2">{getAirport(airports, to).name}</span>
                      </div>
                      <div>
                        <span className="mr-2">
                          {formatDate(depDate, DATE_FORMAT)}
                        </span>
                        {jtype === JourneyType.RETURN ? (
                          <>
                            <span>
                              <i className="fa fa-minus" />
                            </span>
                            <span className="ml-2">
                              {formatDate(retDate, DATE_FORMAT)}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div>{`${
                        parseInt(adults, 10) +
                        parseInt(children, 10) +
                        parseInt(infants, 10)
                      } Traveler(s), ${cls}`}</div>
                    </div>
                    {/* Preloader End */}
                  </div>
                ) : isError ? (
                  <div className="text-center search-summary">
                    <div className="mb-3">
                      <span className="text-1 text-danger d-block">
                        {error}
                      </span>
                    </div>
                  </div>
                ) : fares?.length ? (
                  fares.some((f) => f[INC_CODE]) ? (
                    <>
                      <div className="py-5 bg-light shadow-md mb-2 rounded">
                        <h2 className="text-6 px-3 mb-2 text-center">
                          {getAirport(airports, from).name}{' '}
                          <small className="mx-2">to</small>
                          {getAirport(airports, to).name}
                        </h2>
                        <p className="px-3 text-center search-summary">
                          Showing{' '}
                          <span className="result">
                            {fares.filter((f) => f[INC_CODE]).length}
                          </span>{' '}
                          flights{' '}
                          <span className="d-none d-md-inline-block">
                            from{' '}
                            <span className="result">{`${
                              getAirport(airports, from).name
                            } (${from})`}</span>{' '}
                            to{' '}
                            <span className="result">{`${
                              getAirport(airports, to).name
                            } (${to})`}</span>
                          </span>
                        </p>
                      </div>
                      {fares
                        .filter((f) => f[INC_CODE])
                        .slice(0, showingRecords)
                        .map((f) => (
                          <FlightOverview
                            fare={f}
                            sessionId={data.sessionId}
                            key={f.sourceCode}></FlightOverview>
                        ))}
                      {fares.filter((f) => f[INC_CODE]).length >
                      showingRecords ? (
                        <ButtonBase
                          style={{
                            padding: '16px',
                            backgroundColor: '#0071cc',
                            borderColor: '#0071cc',
                            color: '#fff',
                          }}
                          className="btn btn-primary research-flight mt-3 mt-lg-0"
                          disableRipple
                          onClick={() =>
                            setShowingRecords((r) => r + START_RECORDS_PAGE)
                          }>
                          Load more
                        </ButtonBase>
                      ) : null}
                    </>
                  ) : (
                    getNoDataBlock(
                      <div style={{display: 'flex'}}>
                        No flights were found. Try changing or
                        <ButtonBase
                          classes={{root: classes.buttonBaseRoot}}
                          style={{marginLeft: '4px'}}
                          disableRipple
                          component="a"
                          onClick={clearFilters}>
                          resetting the filters.
                        </ButtonBase>
                      </div>
                    )
                  )
                ) : (
                  getNoDataBlock(
                    'Sorry but no flights were found for this route or date combination.',
                    true
                  )
                )}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Listing;
