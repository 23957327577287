import Application from './config/application';
import queryString from 'query-string';
import {captureException} from '@sentry/react';
import {
  FlightClass,
  JourneyType,
  ListingQParams,
  PageUrl,
  QS_DATE_FORMAT,
} from './Constants';
import {formatDate} from './Util';

const getApiErrorDeducedMsg = (userErrorMsg, apiErrorMsg) =>
  userErrorMsg && userErrorMsg.trim().length
    ? `${userErrorMsg}, ${apiErrorMsg}`
    : apiErrorMsg;

export const handleApiError = (error, showError, message) => {
  // console.log('handleApiError', error.response, error.request);
  if (error.response) {
    captureException(error);
    let errorMsg = error.response.data.message;
    if (!errorMsg || !errorMsg.trim().length) {
      // when there is no message, it could be an error thrown by proxy,
      // read response status and deduce some message.
      const {status} = error.response;
      switch (status) {
        case 401:
          errorMsg = 'User is not authorized';
          break;
        default:
          throw new Error('Got blank error message in response');
      }
    }
    const deducedErrorMsg = getApiErrorDeducedMsg(message, errorMsg);
    if (!showError) {
      return deducedErrorMsg;
    }
    showError(deducedErrorMsg);
    return null;
  }
  if (error.request) {
    captureException(error);
    // !NOTE: Any error thrown within axios, like timeout error will land up here as well,
    // but we're shown server unreachable error. This may be fine for now but keep
    // an eye on errors.
    const msg = error.message;
    let errorMsg;
    if (!navigator.onLine) {
      errorMsg = 'Network error';
    } else if (msg.includes(`timeout of ${error.config.timeout}ms`)) {
      errorMsg =
        'There was delay hearing back from server, you can try for a few times until it resolves';
    } else {
      errorMsg =
        'Server is unreachable. Please try in a few minutes or contact us.';
    }
    const deducedErrorMsg = getApiErrorDeducedMsg(message, errorMsg);
    if (!showError) {
      return deducedErrorMsg;
    }
    showError(deducedErrorMsg);
    return null;
  }
  // log this with tags and see whether wee need to redirect user to a custom
  // error page if this happens because error boundary won't catch it.
  captureException(error, {
    tags: {
      throwing: true,
      unrecoverable: true,
      location: 'HandleApiError',
    },
  });
  // throw as this is unrecoverable error.
  throw new Error(error.message);
};

export const getLocation = (pathname, search, state = {}) => ({
  pathname,
  search,
  state,
});

export const fromJson = (Ctor, json) => Object.assign(new Ctor(), json);

export const isBlank = (text) =>
  !text || !text.replace(/[\s\n\r\t]*/, '').length;

export const getAirline = (airlines, code) => {
  const airline = airlines[code];
  if (!airline) {
    throw new Error(`Unknown airline: ${code}`);
  }
  return airline;
};

export const getAirport = (airports, code) => {
  const airport = airports[code];
  if (!airport) {
    throw new Error(`Unknown airport: ${code}`);
  }
  return airport;
};

export const tryGetAirport = (airports, code) => {
  return airports[code];
};

export const getAirportLoc = (airports, code) => {
  const airport = getAirport(airports, code);
  return `${code}, ${airport.city}`;
};

export const getPluralIfNeeded = (term, number) => {
  return `${term}${number > 1 ? 's' : ''}`;
};

export const getParsedLocationStringified = (parsed) =>
  `?${queryString.stringify(parsed)}`;

export const updateMultipleInSearchQuery = (location, history, map = {}) => {
  const parsed = queryString.parse(location.search);
  Object.keys(map).forEach((k) => {
    parsed[k] = map[k];
  });
  history.push(
    getLocation(
      location.pathname,
      getParsedLocationStringified(parsed),
      location.state
    )
  );
};

export const sendHome = (history, location) => {
  history.replace(getLocation(PageUrl.HOME, location.search, location.state));
};

export const composePageTitle = (text) => `${text} . ${Application.ORG}`;

export const getListingURLWithQS = (from, to, dep, ret) => {
  const params = {
    [ListingQParams.JOURNEY_TYPE]: JourneyType.RETURN,
    [ListingQParams.FROM]: from,
    [ListingQParams.TO]: to,
    [ListingQParams.DEPARTURE_DATE]: formatDate(dep, QS_DATE_FORMAT),
    [ListingQParams.RETURN_DATE]: formatDate(ret, QS_DATE_FORMAT),
    [ListingQParams.ADULTS]: 1,
    [ListingQParams.CHILDREN]: 0,
    [ListingQParams.INFANTS]: 0,
    [ListingQParams.CLASS]: FlightClass.ECONOMY,
  };
  return `${PageUrl.LISTING}${getParsedLocationStringified(params)}`;
};

// For some cities, there is an 'All Airports' suffix and things like that we want to remove
export const normalizeAirportCity = (city) => {
  const allAirportsText = 'All Airports';
  if (city.includes(allAirportsText)) {
    return city.replace(allAirportsText, '');
  }
  return city.trim();
};

export const getHomeLocation = (location) =>
    getLocation(PageUrl.HOME, location.search, location.state);
