import axios from 'axios';
import {Endpoints} from '../Constants';
import {fromJson} from '../Common';
import {
  Fare,
  FareRule,
  FlightDeal,
  Itinerary,
  Stop,
  ValidateFare,
  Airport,
  Airline,
} from '../model';

export const airportSearchFetch = async ({queryKey}) => {
  const [, term] = queryKey;
  if (!term || typeof term !== 'string' || /^\d+/.test(term)) {
    return [];
  }
  const {data} = await axios.get('https://places-api-bvebsyviiq-uw.a.run.app', {
    params: {
      locale: 'en',
      term: term,
    },
  });
  return data;
};

export const flightDealsFetch = async () => {
  const {data} = await axios.get(Endpoints.FLIGHT_DEALS);
  return data.map((d) => fromJson(FlightDeal, d));
};

export const airportsFetch = async () => {
  const {data} = await axios.get(Endpoints.AIRPORTS);
  const codeToAirports = {};
  Object.keys(data).map(
    (k) => (codeToAirports[k] = fromJson(Airport, data[k]))
  );
  return codeToAirports;
};

export const airlinesFetch = async () => {
  const {data} = await axios.get(Endpoints.AIRLINES);
  const codeToAirlines = {};
  Object.keys(data).map(
    (k) => (codeToAirlines[k] = fromJson(Airline, data[k]))
  );
  return codeToAirlines;
};

export const fareValidateFetch = async ({queryKey}) => {
  const [, sessionId, sourceCode] = queryKey;

  const {data} = await axios.get(Endpoints.FARE_VALIDATE, {
    params: {
      sessionId,
      sourceCode,
    },
  });
  const vFare = fromJson(ValidateFare, data);
  vFare.fare = fromJson(Fare, vFare.fare);
  return vFare;
};

export const fareRulesFetch = async ({queryKey}) => {
  const [, sessionId, sourceCode] = queryKey;

  const {data} = await axios.get(Endpoints.FARE_RULES, {
    params: {
      sessionId,
      sourceCode,
    },
  });
  return data.fareRules.map((r) => fromJson(FareRule, r));
};

export const flightSearchFetch = async ({queryKey}) => {
  const [
    ,
    jtype,
    from,
    to,
    dep,
    ret,
    adults,
    children,
    infants,
    cls,
    testing,
  ] = queryKey;

  let result = null;

  if (!testing) {
    result = await axios.get(Endpoints.FLIGHT_SEARCH, {
      params: {
        journeyType: jtype,
        from,
        to,
        departureDate: dep,
        returnDate: ret,
        adults,
        children,
        infants,
        classType: cls,
      },
    });
  } else {
    result = {};
    if (jtype === 'OneWay') {
      result.data = require('../data/oneway_dev').default;
    } else if (jtype === 'Return') {
      result.data = require('../data/twoway_dev').default;
    }
  }

  const {data} = result;

  if (!data || data.fares?.length === 0) {
    return {fares: [], sessionId: null};
  }

  const fares = data.fares.map((f) => fromJson(Fare, f));
  fares.forEach((f) => {
    f.itineraries = f.itineraries.map((i) => fromJson(Itinerary, i));
    f.itineraries.forEach((i) => {
      i.stops = i.stops.map((s) => fromJson(Stop, s));
    });
    // Add a few more things in fare for convenience
    f.pax = {adults, children, infants};
  });

  const {sessionId} = data;

  return {fares, sessionId};
};
