import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Terms = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('Terms and Conditions')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Terms and Conditions</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">Terms and Conditions</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
            <div>
              <h6 class="top-gap"> BOOKING CONDITIONS </h6>
              <p class="about_txt">
                Our website aims to help you to collect travel details and to
                determine the availability of travel-related products and
                services, to make legitimate reservations and to accomplish
                transactions with different travel suppliers. Before you should
                avail our offered services, you should make sure reading our
                Terms and Conditions as follows-
              </p>
              <h6 class="top-gap">
                {' '}
                <strong>Eligibility to User Our Service </strong>{' '}
              </h6>
              <p>
                Our website and offered services to all users, who are either 18
                years age or above. By using our website, you warrant about
                meeting each of the foregoing eligibility requirements. In case
                you fail to meet any requirement, you cannot get access or use
                our travel website.
              </p>
              <p>
                Most interactive websites use cookies to let us retrieve the
                user's details for each visit. Cookies are used by our website
                to enable the functionality of certain areas to make it easier
                for people visiting our website. Some of our
                affiliate/advertising partners may also use cookies.
              </p>
              <h6 class="top-gap">
                {' '}
                <strong> Changes in Our Terms of Use</strong>{' '}
              </h6>
              <p>
                We regularly revise and update our Terms of Usage in the sole
                discretion. Each of our changes will come as an immediate effect
                after we post about them in our portal and become applicable to
                each of the accesses and usages of site thereafter. You may
                continue using the services offered by our website after you
                accept our updated and revised terms and conditions. Hence, you
                should check our terms of use page regularly and each time
                whenever you access our website, so that you stay aware with any
                change, which may bind on you.
              </p>
              <h6 class="top-gap">
                {' '}
                <strong>
                  {' '}
                  <p>The responsibility of Our Users</p> <p>As users of our{' '}
                  {Application.ORG_DISPLAY_NAME} website, you have to take the
                  responsibility for-</p>
                </strong>{' '}
              </h6>
              <ul>
                <li>
                  To make each of the essential arrangements to access our
                  website
                </li>
                <li>
                  To make sure that each person accessing our website stays
                  fully aware of our terms of use and comply them strictly.
                </li>
              </ul>
              <p>
                These Terms & Conditions and the rights of the parties hereunder
                shall be governed by and construed in accordance with the laws
                of the State of Texas, USA.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
