export const DATE_FORMAT = 'eee, MMM dd, yyyy';
export const TIME_FORMAT = `hh:mm aaa`;
export const PAX_LIMIT = 9;

export const PageUrl = {
  HOME: '/',
  LISTING: '/listing',
  BOOKING: '/booking',
  PAYMENT: '/payment',
  THANK_YOU: '/thank-you',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  FAQ: '/faq',
  DESTINATION: '/destinations',
  ABOUT: '/about',
  CONTACT: '/contact',
  CANCEL_REFUND_POLICY: '/cancel-refund-policy',
  FLIGHT: '/flight'
};

export const QueryKeys = {
  LISTING: 'listing',
  AIRPORT_SEARCH_FROM: 'airportSearchFrom',
  AIRPORT_SEARCH_TO: 'airportSearchTO',
  DEALS: 'deals',
  FARE_RULES: 'fareRules',
  FARE_VALIDATE: 'fareValidate',
  AIRPORTS: 'airports',
  AIRLINES: 'airlines'
};

export const Endpoints = {
  FLIGHT_SEARCH: 'flightSearch',
  FLIGHT_DEALS: 'flightDeals',
  FLIGHT_PRE_BOOK: 'flightBook/preBook',
  FLIGHT_BOOK: 'flightBook/book',
  FARE_RULES: 'fare/getRules',
  FARE_VALIDATE: 'fare/validate',
  AIRLINES: 'static/airlines',
  AIRPORTS: 'static/airports',
};

export const ListingQParams = {
  JOURNEY_TYPE: 'jtype',
  FROM: 'from',
  TO: 'to',
  DEPARTURE_DATE: 'dep',
  RETURN_DATE: 'ret',
  ADULTS: 'adults',
  CHILDREN: 'children',
  INFANTS: 'infants',
  CLASS: 'cls',
  TEST: 'testing',
};

export const FlightClass = {
  FIRST: 'First',
  BUSINESS: 'Business',
  ECONOMY: 'Economy',
  PREMIUM_ECONOMY: 'PremiumEconomy',
};

export const TravelerType = {
  ADULTS: 'Adults',
  CHILDREN: 'Children',
  INFANTS: 'Infants',
};

export const TravelerTypeToAge = {
  ADULTS: '12+',
  CHILDREN: '2-12',
  INFANTS: '0-2',
};

export const JourneyType = {
  RETURN: 'Return',
  ONE_WAY: 'OneWay',
};

export const MATH_OPERATION = {
  PLUS: '+',
  MINUS: '-',
};

export const QS_DATE_FORMAT = 'yyyy-MM-dd';

export const SnackbarType = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
};

export const SnackbarVerPos = {
  BOTTOM: 'bottom',
  TOP: 'top',
};

export const SnackbarHorPos = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const PAGE_IDS = {
  FLIGHT: 'flight'
};
