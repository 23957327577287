/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import {Fare} from '../model';
import {
  getAirline,
  getAirport,
  getAirportLoc,
  getLocation,
  getPluralIfNeeded,
} from '../Common';
import {formatDate, getFormattedMins, roundFormatMoney} from '../Util';
import {useHistory, useLocation} from 'react-router-dom';
import {DATE_FORMAT, PageUrl, QueryKeys, TIME_FORMAT} from '../Constants';
import {useQuery} from 'react-query';
import {airlinesFetch, airportsFetch, fareRulesFetch} from '../api/fetches';

const useStyles = makeStyles((theme) => ({
  buttonBaseRoot: {
    color: '#0071cc',
    padding: '.5rem 1rem',
  },
}));

const TabTypes = {
  DEPART: 'DEPART',
  RETURN: 'RETURN',
  FARE_DETAILS: 'FARE_DETAILS',
  FARE_RULES: 'FARE_RULE',
};

const FlightDetails = React.memo(({fare, sessionId, onClose}) => {
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  const {data: rules} = useQuery(
    [QueryKeys.FARE_RULES, sessionId, fare.sourceCode],
    fareRulesFetch,
    {
      staleTime: 20 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );

  const {data: airports} = useQuery([QueryKeys.AIRPORTS], airportsFetch, {
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const {data: airlines} = useQuery([QueryKeys.AIRLINES], airlinesFetch, {
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const sendBooking = () => {
    history.push(
      getLocation(PageUrl.BOOKING, null, {fare, sessionId, location})
    );
  };

  const getItinContent = useCallback((stops) => {
    const totalStops = stops.length - 1;
    return (
      <div>
        <div className="text-0" style={{whiteSpace: 'nowrap'}}>
          {totalStops > 0
            ? `${totalStops} ${getPluralIfNeeded('Stop', totalStops)} | `
            : ''}
          {stops[0].cabinClassText} | Total trip time:{' '}
          {getFormattedMins(
            stops.reduce((total, s) => total + s.journeyDurationMins, 0)
          )}
        </div>
        {stops.map((s, index) => (
          <div
            className="d-flex align-items-center flex-column flex-md-row position-relative"
            key={index}>
            <div className="px-3 py-2 align-items-center d-flex company-info">
              <span>
                <img
                  alt={s.airlineCode}
                  src={getAirline(airlines, s.airlineCode).logo}
                  style={{width: '3.5rem'}}
                />
              </span>
              <span className="align-middle ml-2">
                <span
                  className="d-block text-1 text-dark"
                  style={{whiteSpace: 'nowrap'}}>
                  {getAirline(airlines, s.airlineCode).name}
                </span>
                <small className="text-muted d-block">{s.flightNumber}</small>
              </span>
            </div>
            <div className="px-3 py-2 d-flex align-items-center justify-content-between flight-time-in-out w-100">
              <div className="text-center">
                <div className="text-0" style={{whiteSpace: 'nowrap'}}>
                  {formatDate(new Date(s.departureDateTime), DATE_FORMAT)}
                </div>
                <div className="text-0">
                  {formatDate(new Date(s.departureDateTime), TIME_FORMAT)}
                </div>
                <small className="text-muted" style={{whiteSpace: 'nowrap'}}>
                  {getAirportLoc(airports, s.departureAirportLocationCode)}
                </small>
              </div>
              <div className="time-info text-center w-100 mx-3 position-relative">
                <div className="d-flex align-items-center">
                  <span>
                    <i className="fa fa-map-marker-alt mr-2" />
                  </span>
                  <div className="devider w-100 d-flex justify-content-around align-items-center">
                    <div className="">
                      <div className="text-muted stop">Duration</div>
                      <div className="text-1 total-time">
                        {getFormattedMins(s.journeyDurationMins)}
                      </div>
                    </div>
                  </div>
                  <span>
                    <i className="fa fa-map-marker-alt ml-2" />
                  </span>
                </div>
              </div>
              <div className="text-center">
                <div className="text-0" style={{whiteSpace: 'nowrap'}}>
                  {formatDate(new Date(s.arrivalDateTime), DATE_FORMAT)}
                </div>
                <div className="text-0">
                  {formatDate(new Date(s.arrivalDateTime), TIME_FORMAT)}
                </div>
                <small className="text-muted" style={{whiteSpace: 'nowrap'}}>
                  {getAirportLoc(airports, s.arrivalAirportLocationCode)}
                </small>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }, [airlines, airports]);

  const setDepartContent = useCallback(() => {
    setActiveTab(TabTypes.DEPART);
    setTabContent(getItinContent(fare.itineraries[0].stops));
  }, [fare, getItinContent]);

  const setReturnContent = () => {
    setActiveTab(TabTypes.RETURN);
    setTabContent(getItinContent(fare.itineraries[1].stops));
  };

  const setFareDetailsContent = () => {
    setActiveTab(TabTypes.FARE_DETAILS);
    setTabContent(
      <div className="table-responsive-md">
        <table className="table table-hover table-bordered bg-light">
          <tbody>
            <tr>
              <td>Base Fare</td>
              <td className="text-right">{roundFormatMoney(fare.baseFare)}</td>
            </tr>
            <tr>
              <td>Taxes</td>
              <td className="text-right">{roundFormatMoney(fare.totalTax)}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td className="text-right">
                {roundFormatMoney(fare.baseFare + fare.totalTax)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const setFareRules = () => {
    setActiveTab(TabTypes.FARE_RULES);
    setTabContent(
      <div>
        {rules.map((r, index) => (
          <div key={index}>
            <div className="px-3 py-2 align-items-center d-flex company-info">
              <span>
                <img
                  alt={r.airline}
                  src={getAirline(airlines, r.airline).logo}
                  style={{width: '3.5rem'}}
                />
              </span>
              <span className="align-middle ml-2">
                <span
                  className="d-block text-1 text-dark"
                  style={{whiteSpace: 'nowrap'}}>
                  {getAirline(airlines, r.airline).name} Fare Rules:
                </span>
              </span>
            </div>
            <div className="text-1 py-2">
              <p>Category: {r.category}</p>
              {r.rules}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const [activeTab, setActiveTab] = useState(TabTypes.DEPART);
  const [tabContent, setTabContent] = useState(null);

  const oneway = fare.itineraries.length === 1;

  useEffect(() => {
    setDepartContent();
  }, [setDepartContent]);

  return (
    <div className="modal-content">
      <div className="modal-header">
        <div style={{display: 'flex'}}>
          <span className="text-1 text-muted d-block">
            {oneway ? 'Oneway' : 'Roundtrip'} price for all travelers, including
            taxes and fees:
          </span>
          <span className="text-dark text-5" style={{marginLeft: '5px'}}>
            {roundFormatMoney(fare.baseFare + fare.totalTax)}
          </span>
        </div>
        <button
          type="button"
          className="close"
          onClick={onClose}
          aria-label="Close">
          {' '}
          <span aria-hidden="true">&times;</span>{' '}
        </button>
      </div>
      <div className="modal-body">
        <div className="flight-details">
          <div className="row mb-4">
            <div className="col-12 col-sm-9 col-lg-8">
              {fare.itineraries.map((i, index) => (
                <div
                  className="row align-items-center trip-title mb-3"
                  key={index}>
                  <div className="col col-sm-auto text-center text-sm-left">
                    <h5 className="m-0 trip-place">
                      {
                        getAirport(
                          airports,
                          i.stops[0].departureAirportLocationCode
                        ).city
                      }
                    </h5>
                  </div>
                  <div className="col-auto text-10 text-black-50 text-center trip-arrow">
                    ➝
                  </div>
                  <div className="col col-sm-auto text-center text-sm-left">
                    <h5 className="m-0 trip-place">
                      {
                        getAirport(
                          airports,
                          i.stops[i.totalStops].arrivalAirportLocationCode
                        ).city
                      }
                    </h5>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-6 col-sm-3 col-lg-2 text-right ml-auto btn-book d-flex align-items-center justify-content-end">
              {location.pathname.startsWith(PageUrl.LISTING) ? (
                <button
                  className="btn btn-sm btn-primary"
                  onClick={sendBooking}>
                  <i className="fas fa-shopping-cart d-block d-lg-none" />
                  <span className="d-none d-lg-block">Book</span>
                </button>
              ) : null}
            </div>
          </div>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              {' '}
              <ButtonBase
                classes={{root: classes.buttonBaseRoot}}
                disableRipple
                component="a"
                onClick={setDepartContent}
                className={clsx(
                  'nav-link',
                  activeTab === TabTypes.DEPART && 'active'
                )}
                role="tab"
                aria-selected={activeTab === TabTypes.DEPART}>
                Depart
              </ButtonBase>{' '}
            </li>
            {!oneway ? (
              <li className="nav-item">
                {' '}
                <ButtonBase
                  classes={{root: classes.buttonBaseRoot}}
                  disableRipple
                  component="a"
                  onClick={setReturnContent}
                  className={clsx(
                    'nav-link',
                    activeTab === TabTypes.RETURN && 'active'
                  )}
                  role="tab"
                  aria-selected={activeTab === TabTypes.RETURN}>
                  Return
                </ButtonBase>{' '}
              </li>
            ) : null}
            <li className="nav-item">
              {' '}
              <ButtonBase
                classes={{root: classes.buttonBaseRoot}}
                disableRipple
                component="a"
                onClick={setFareDetailsContent}
                className={clsx(
                  'nav-link',
                  activeTab === TabTypes.FARE_DETAILS && 'active'
                )}
                role="tab"
                aria-selected={activeTab === TabTypes.FARE_DETAILS}>
                Fare Details
              </ButtonBase>{' '}
            </li>
            <li className="nav-item">
              {' '}
              <ButtonBase
                classes={{root: classes.buttonBaseRoot}}
                disableRipple
                component="a"
                onClick={setFareRules}
                className={clsx(
                  'nav-link',
                  activeTab === TabTypes.FARE_RULES && 'active'
                )}
                role="tab"
                aria-selected={activeTab === TabTypes.FARE_RULES}>
                Fare Rules
              </ButtonBase>{' '}
            </li>
          </ul>
          <div className="tab-content my-3" id="myTabContent">
            <div className="tab-pane fade show active" role="tabpanel">
              {tabContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

FlightDetails.propTypes = {
  fare: PropTypes.instanceOf(Fare).isRequired,
  sessionId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FlightDetails;
