import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Destinations = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('Destinations')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Destinations</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">Destinations </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
            <h2>
              Avail The Perfect Destination By Booking Cheap Flight Ticket
            </h2>
            <p>
              When you are planning a trip to USA or Canada, then you can
              approach flight booking brothers {Application.ORG_DISPLAY_NAME} to
              perform everything which you need for. You can easily book online
              flight tickets in few minutes by accessing our entryway and hence
              get ready for your trip. {Application.ORG_DISPLAY_NAME} provides a horde
              of air-tickets for numerous flights from various destinations in
              just a few minute clicks.
            </p>
            <p>
              Irrespective of whether it is round-trip or one-route, {Application.ORG_DISPLAY_NAME} provides you the facility to have a flight booking
              online from anywhere and anyplace. In the same manner, you can
              also have the choice for picking perfect className such as
              Business Class or Economy Class from the comfort of your house.
              Always check <strong>Offers on flight booking,</strong> before you
              plan to book.
            </p>
            <div>
              <h3>What Are The Special Destinations And Moments?</h3>
              <ol>
                <li>
                  We have exciting Flights Unpublished Deals on famous travel
                  destinations, including Paris, Miami, Ontario (London), Los
                  Angeles (USA), Sydney (Australia) and New York (USA).
                </li>
                <li>
                  Irrespective of whether planning for a multi-city excursion or
                  round outing or need to book tickets for any of these areas,
                  do not forget to check the discounts and offers associated
                  with this, as it will provide you the capability to save your
                  money on online flight reservations.
                </li>
                <li>
                  The offers and deals change continuously, thus creating a
                  beeline for flight booking framework with the spirit of
                  purchasing tickets for getting air transport. We will also
                  ensure to provide tickets easily with additional rebates.
                </li>
                <li>
                  The additional landing and take-off time of the flight might
                  be slightly varying from the schedule. However, very late
                  changes will the updated constantly.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <section className="section">
          <div className="container">
            <h2 className="text-9 fw-500 text-center">Popular Destinations</h2>
            <p className="lead text-center mb-4">
              World's leading Flight Booking website.
            </p>
            <div className="row g-4 banner">
              <div className="col-md-8">
                <div className="item rounded h-100">
                  <div className="caption text-center">
                    <h2 className="text-7">Dubai</h2>
                  </div>
                  <div className="banner-mask"></div>
                  <img
                    className="img-fluid h-100"
                    src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/dubai.jpg`}
                    alt="Dubai"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="row g-4">
                  <div className="col-12">
                    <div className="item rounded">
                      <div className="caption text-center">
                        <h2>India</h2>
                      </div>
                      <div className="banner-mask"></div>
                      <img
                        className="img-fluid"
                        src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/india.jpg`}
                        alt="India"
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-lg-3">
                    <div className="item rounded">
                      <div className="caption text-center">
                        <h2>London</h2>
                      </div>
                      <div className="banner-mask"></div>
                      <img
                        className="img-fluid"
                        src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/lodon.jpg`}
                        alt="London"
                      />{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row banner g-4 mt-0 mb-2 mt-lg-3">
              <div className="col-md-4">
                <div className="row g-4">
                  <div className="col-12">
                    <div className="item rounded">
                      <div className="caption text-center">
                        <h2>Bangkok</h2>
                      </div>
                      <div className="banner-mask"></div>
                      <img
                        className="img-fluid"
                        src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/bangkok.jpg`}
                        alt="Bangkok"
                      />{' '}
                    </div>
                  </div>
                  <div className="col-12 mt-lg-3">
                    <div className="item rounded">
                      <div className="caption text-center">
                        <h2>NYC</h2>
                      </div>
                      <div className="banner-mask"></div>
                      <img
                        className="img-fluid"
                        src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/newyork.jpg`}
                        alt="NYC"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="item rounded h-100">
                  <div className="caption text-center">
                    <h2 className="text-7">Singapore</h2>
                  </div>
                  <div className="banner-mask"></div>
                  <img
                    className="img-fluid h-100"
                    src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/singapore.jpg`}
                    alt="Singapore"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Destinations;
