import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const About = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('About Us')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>About Us</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
            <h2 className="text-6">Welcome to {Application.ORG_DISPLAY_NAME} - Your Gateway to Exclusive US Airlines Deals!</h2>
            <p>
              Welcome to {Application.ORG_DISPLAY_NAME}.  we are your trusted travel agency specializing in bringing you the best deals and offers exclusively from top US airlines. Whether you're planning a weekend getaway, a family vacation, or a business trip, our mission is to make your travel experience seamless, affordable, and unforgettable.
            </p>
            <p><strong>Exclusive US Airlines: </strong><br></br>
            As a dedicated travel agency dealing solely with US airlines, we have established strong partnerships that grant us access to exclusive deals and discounts. Rest assured, you'll have the best options to choose from for your domestic and international flights.</p>
            <p><strong>Best Deals and Offers: </strong><br></br>
            Our team of travel experts scours the market to bring you the most competitive fares available. We understand the value of your hard-earned money, and our commitment to finding the best deals is unwavering.</p>
            <p><strong>Personalized Service:</strong><br></br>
            We know that every traveler is unique, and we take pride in offering personalized assistance to cater to your specific preferences and needs. From preferred seating to special meal requests, we go the extra mile to make your journey comfortable and enjoyable.</p>
            <p><strong>Hassle-Free Booking:</strong><br></br>
            With psstravel, booking your flights is a breeze. Our user-friendly website and experienced agents make the reservation process quick, easy, and efficient.</p>
            <p><strong>24/7 Customer Support: </strong><br></br>
            psstravel is more than just a travel agency; we are your reliable partners throughout your journey. Our dedicated customer support team is available round-the-clock to assist you with any queries or concerns.</p>
            <p><strong>Secure and Trustworthy: </strong><br></br>
            Your trust is our priority. As a third-party agency, we maintain the highest standards of security and confidentiality to safeguard your personal information.</p>
            <p><strong>Explore Iconic Destinations: </strong><br></br>
            From the bustling streets of New York City to the sunny beaches of Los Angeles, we offer a diverse range of destinations for every traveler's taste.</p>
            <p><strong>Uncover Hidden Gems: </strong><br></br>
            Venture off the beaten path and discover lesser-known treasures across the US. Let us help you create unforgettable memories in charming towns and scenic landscapes.</p>
            <p><strong>Business Travel Made Easy: </strong><br></br>
            If you're a frequent flyer on business trips, psstravel ensures a seamless travel experience with flexible options and tailored itineraries to suit your busy schedule.</p>
            <p><strong>Book with Confidence:</strong><br></br>
            psstravel is dedicated to making your travel dreams a reality. We're passionate about connecting you to the world with unbeatable deals and exceptional service. With us, your journey begins the moment you envision it.</p>
            {/* <div className="row mt-4 mb-2">
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-thumbs-up"></i>
                  </div>
                  <h3>Best Deal Offer</h3>
                  <p>
                    Find our lowest deal to destinations worldwide, guaranteed
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-paper-plane"></i>{' '}
                  </div>
                  <h3>Easy Booking</h3>
                  <p>
                    Search, select and save - the fastest way to book your trip
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-eye"></i>{' '}
                  </div>
                  <h3>24/7 Customer Care</h3>
                  <p>
                    Get award-winning service and special deals by calling{' '}
                    {Application.SUPPORT_PHONE}
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
