/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import {makeStyles} from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';
import '../vendor/bootstrap/css/bootstrap.min.css';
import '../vendor/font-awesome/css/all.min.css';
import '../css/stylesheet.css';
import '../css/custom.css';
import {formatDate, getFormattedMins, roundFormatMoney} from '../Util';
import {Fare} from '../model';
import {
  getAirline,
  getAirportLoc,
  getLocation,
  getPluralIfNeeded,
} from '../Common';
import FlightDetails from './FlightDetails';
import {DATE_FORMAT, PageUrl, QueryKeys, TIME_FORMAT} from '../Constants';
import { useQuery } from 'react-query';
import { airlinesFetch, airportsFetch } from '../api/fetches';

const useStyles = makeStyles((theme) => ({
  buttonBaseRoot: {
    color: '#0071cc',
  },
}));

const Transition = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction="down" ref={ref} {...props} />
));

const FlightOverview = React.memo(({fare, sessionId}) => {
  const [showDetail, setShowDetail] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const oneway = fare.itineraries.length === 1;

  const classes = useStyles();

  const {data: airports} = useQuery([QueryKeys.AIRPORTS], airportsFetch, {
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const {data: airlines} = useQuery([QueryKeys.AIRLINES], airlinesFetch, {
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const sendBooking = () => {
    history.push(getLocation(PageUrl.BOOKING, null, {fare, sessionId, location}));
  };

  const getItinFromToBlock = (i) => (
    <div
      className={`${
        oneway ? 'px-3 py-2' : ''
      } d-flex align-items-center justify-content-between flight-time-in-out`}>
      <div className="text-center">
        {/* Start and end locations will be the first stop's depart loc and last stop's arrival loc */}
        <div className="text-0" style={{whiteSpace: 'nowrap'}}>
          {formatDate(new Date(i.stops[0].departureDateTime), DATE_FORMAT)}
        </div>
        <div className="text-0">
          {formatDate(new Date(i.stops[0].departureDateTime), TIME_FORMAT)}
        </div>
        <small className="text-muted" style={{whiteSpace: 'nowrap'}}>
          {getAirportLoc(airports, i.stops[0].departureAirportLocationCode)}
        </small>
      </div>
      <div className="time-info text-center w-100 mx-3 position-relative">
        <div className="d-flex align-items-center">
          <span>
            <i className="fa fa-map-marker-alt mr-2" />
          </span>
          <div className="devider w-100 d-flex justify-content-around align-items-center">
            {i.totalStops === 0 ? (
              <div className="">
                <div className="text-muted stop">Non Stop</div>
                <div className="text-1 total-time">
                  {getFormattedMins(i.stops[0].journeyDurationMins)}
                </div>
              </div>
            ) : (
              <>
                {i.stops.slice(1).map((s) => (
                  <div
                    className="stop-icon"
                    key={s.departureAirportLocationCode}>
                    {/* The logic of stop markers is: From all stops, skip the first one and show the depart location from every stop. */}
                    <div
                      className="text-muted stop"
                      style={{marginBottom: '2rem'}}>
                      {s.departureAirportLocationCode}
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    position: 'absolute',
                    paddingTop: '0.75rem',
                    marginTop: '1rem',
                  }}>
                  <div className="text-1 total-time">
                    {getFormattedMins(
                      i.stops.reduce(
                        (total, s) => total + s.journeyDurationMins,
                        0
                      )
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <span>
            <i className="fa fa-map-marker-alt ml-2" />
          </span>
        </div>
      </div>
      <div className="text-center">
        <div className="text-0" style={{whiteSpace: 'nowrap'}}>
          {formatDate(
            new Date(i.stops[i.totalStops].arrivalDateTime),
            DATE_FORMAT
          )}
        </div>
        <div className="text-0">
          {formatDate(
            new Date(i.stops[i.totalStops].arrivalDateTime),
            TIME_FORMAT
          )}
        </div>
        <small className="text-muted" style={{whiteSpace: 'nowrap'}}>
          {getAirportLoc(airports, i.stops[i.totalStops].arrivalAirportLocationCode)}
        </small>
      </div>
    </div>
  );

  const getItinFlightBlock = (i) => (
    <div
      className={`${oneway ? 'px-3 py-2' : 'mr-0 mr-md-5'} d-flex company-info`}
      style={{flexDirection: 'column'}}>
      <span>
        <img
          alt={i.stops[0].airlineCode}
          src={getAirline(airlines, i.stops[0].airlineCode).logo}
          style={{width: '3.5rem'}}
        />
      </span>
      <span className="align-middle">
        <span
          className="d-block text-0 text-dark"
          style={{whiteSpace: 'nowrap'}}>
          {getAirline(airlines, i.stops[0].airlineCode).name}
        </span>
        <small className="text-muted d-block">{i.stops[0].flightNumber}</small>
      </span>
    </div>
  );

  return (
    <>
      <div
        className={`flight-item bg-light shadow-md mb-3 ${
          oneway ? 'one-way-result' : 'return-result'
        }`}>
        <div className="d-flex align-items-center justify-content-between flex-column flex-md-row position-relative">
          {oneway ? (
            <>
              {getItinFlightBlock(fare.itineraries[0])}
              {getItinFromToBlock(fare.itineraries[0])}
            </>
          ) : (
            <div className="w-100">
              {fare.itineraries.map((i, index) => (
                <div
                  className="d-flex align-items-center flex-column flex-md-row px-3 py-2"
                  key={index}>
                  {getItinFlightBlock(i)}
                  <div className="d-flex justify-content-center w-100">
                    {getItinFromToBlock(i)}
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="ml-0 ml-md-5 d-flex p-3 justify-content-between align-items-center d-md-block price-book">
            <div className="text-md-right text-left mb-2">
              <div className="price text-6 text-success">
                {roundFormatMoney(fare.perAdultTotalFare)}
              </div>
              <div className="small">Price Per Adult</div>
            </div>
            <div className="btn-book text-md-right">
              {/*<RouterLink
                to={`${PageUrl.BOOKING}/${fare.sourceCode}`}
                className="btn btn-primary">
                <i className="fas fa-shopping-cart d-block d-lg-none" />
                <span className="d-none d-lg-block">Book</span>
              </RouterLink>*/}
              {location.pathname.startsWith(PageUrl.LISTING) ? (
                <button className="btn btn-primary" onClick={sendBooking}>
                  <i className="fas fa-shopping-cart d-block d-lg-none" />
                  <span className="d-none d-lg-block">Book</span>
                </button>
              ) : null}
            </div>
            <div className="flight-details-link text-right mt-3">
              <ButtonBase
                classes={{root: classes.buttonBaseRoot}}
                disableRipple
                component="a"
                onClick={() => setShowDetail(true)}>
                Flight Details
              </ButtonBase>
            </div>
            <div className="text-md-right text-left">
              <span className="text-1 text-danger d-block">
                {fare.itineraries[0].stops[0].seatsRemaining}{' '}
                {getPluralIfNeeded(
                  'seat',
                  fare.itineraries[0].stops[0].seatsRemaining
                )}{' '}
                left
              </span>
            </div>
            {!fare.refundable ? (
              <div className="text-md-right text-left">
                <span className="text-1 text-danger d-block">
                  Non Refundable
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Dialog
        TransitionComponent={Transition}
        onClose={() => setShowDetail(false)}
        open={showDetail}
        maxWidth="lg">
        <FlightDetails sessionId={sessionId} fare={fare} onClose={() => setShowDetail(false)} />
      </Dialog>
    </>
  );
});

FlightOverview.propTypes = {
  fare: PropTypes.instanceOf(Fare).isRequired,
  sessionId: PropTypes.string.isRequired,
};

export default FlightOverview;
