import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Privacy = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('Privacy Policy')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Privacy Policy</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">Privacy Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
            <div>
              <p class="about_txt">
                {' '}
                The document of Privacy policy explains the policy and practices that {Application.ORG_DISPLAY_NAME} utilizes to maintain their customers' personal information. Our objective is to maintain all the information that are provided to us. As a user of {Application.ORG_DISPLAY_NAME}, you agree with these following terms and conditions.
              </p>
              <p class="about_txt">
                {' '}
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>

              <h4 class="top-gap">
                {' '}
                <strong>Privacy Information that we gather from customers and how we utilize this information </strong>{' '}
              </h4>
              <p class="about_txt">
                {' '}
                'Individual Information' implies, those information which are particularly related to specific person for example, name, address, phone number, email address, Mastercard number, cardholder name, expiration date, data about the travel, appointments, travelers, your frequent flier Id no and any other that can be essential from the client.

We get and store each data that you enter on our website through email, fax, and phone or in some other way. This particular information which includes your first and surname, phone number, postal and email addresses, and also billing information (for example, Visa number, cardholder name, and expiration date) that will recognize you through these unique information. We always keep confidential all the information of travelers' about preference as well like meal preference, choice of seat, if you are frequent flier customer then your car rental choice, your ticketing options and numerous.{' '}
              </p>
              <h4 class="top-gap">
                {' '}
                <strong> Customers' information </strong>{' '}
              </h4>
              <p class="about_txt">
                {' '}
                When customers want to avail our travel services, they provide some personal information and travel preference to us. If you want to book tickets for other individual through your account, you have to consent of them prior to provide, as these information varies and unique person to person.{' '}
              </p>

              <h4 class="top-gap">
                {' '}
                <strong> Registered member </strong>{' '}
              </h4>
              <p class="about_txt">
              If you are our registered member of our company, you have to provide all your personal details including your name, address, telephone number, e-mail address, a unique login name, password, and password validation, and a password hint to help you remember your password. All these informations are aggregated on our registration form for various purposes.


              </p>
              <ul>
                <li>
                Individual identification
                </li>
                <li>To complete your journey with ease</li>
                <li>To let us connect you for client support and further special offers.</li>
                <li>To confirm your new member registration and each reservation you make.</li>
                
              </ul>
              <h4 class="top-gap">
                {' '}
                <strong> Automation process of collecting information </strong>{' '}
              </h4>
              <p class="about_txt">
              We additionally gather data from our business associates and other autonomous resources and add it to our record. For example, we will collect your IP address, Web browser software (such as Netscape Navigator or Internet Explorer), and referring Web site. We also may collect information about your online activity. Our aim behind collecting this automatic information is to help customize your user experience.
              </p>
              <h4 class="top-gap">
                {' '}
                <strong> We may share your details with whom!!! </strong>{' '}
              </h4>
              <p class="about_txt">
              In case of summons, court orders, or some other legal procedure for establishing or practicing our legal rights in order to protect against legal claims. In this kind of situation, we would reserve the right to raise/waive off any legal objection/right that is available to us.
                <br />
                When we believe that it will be appropriate to initiate investigation, prevent or take action regarding illegal/suspected illegal activities; for protecting and defending the rights, property or security of our company or this very website, our customers or others and in accordance with our Terms & Conditions and other bound agreements.
                <br />
                Other than what is portrayed above you will be given notice when individual personal details about you are being shared with independent entities, and you will have access to a chance to select not to have us unveil or share such data.
                <br />
                We may likewise share unknown or gathered information to autonomous substances, including publicists and also investors. For example, we may tell our advertisers the aggregate number of guests that more often visit our site or the most well-known lodgings and get-away destinations. This data does not contain any individual data and is just used to create content and other services that may be of interest to you.
                <br />
                We at {Application.ORG_DISPLAY_NAME} collect from you your personal and private information and respect your privacy. We guarantee the certainty to protect customers' private and personal information, which they submit to us through the contact form on our website. The information includes address, name, telephone number, etc. We don't sell, trade, share or rent the information to third parties without the customers' prior permission, unless otherwise here in described.
                <br />
                {Application.ORG_DISPLAY_NAME} uses customers' debit and credit card number for their payment process. Other confidential information we collect is not shared with third parties until or unless we are required to do so by the law, and when we believe that disclosure is essential for protecting our rights and/or complying with a court order, judicial proceeding, or legal process served on our Site
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
