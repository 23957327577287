import React from 'react';
import PropTypes from 'prop-types';

const CountrySelect = React.memo(({className, value, required, onChange}) => {
  return (
    <select
      name="country"
      id="country"
      className={className}
      value={value}
      onChange={onChange}>
      <option value="">Select Country{required ? '*' : ''}</option>
      <option value="US">UNITED STATES</option>
      <option value="CA">CANADA</option>
      <option value="GB">United Kingdom</option>
      <option value="ad">Andorra, Principality of</option>
      <option value="ae">United Arab Emirates</option>
      <option value="af">Afghanistan, Islamic State of</option>
      <option value="ag">Antigua and Barbuda</option>
      <option value="ai">Anguilla</option>
      <option value="al">Albania</option>
      <option value="am">Armenia</option>
      <option value="an">Netherlands Antilles</option>
      <option value="ao">Angola</option>
      <option value="aq">Antarctica</option>
      <option value="ar">Argentina</option>
      <option value="as">American Samoa</option>
      <option value="at">Austria</option>
      <option value="au">Australia</option>
      <option value="aw">Aruba</option>
      <option value="az">Azerbaidjan</option>
      <option value="ba">Bosnia-Herzegovina</option>
      <option value="bb">Barbados</option>
      <option value="bd">Bangladesh</option>
      <option value="be">Belgium</option>
      <option value="bf">Burkina Faso</option>
      <option value="bg">Bulgaria</option>
      <option value="bh">Bahrain</option>
      <option value="bi">Burundi</option>
      <option value="bj">Benin</option>
      <option value="bm">Bermuda</option>
      <option value="bn">Brunei Darussalam</option>
      <option value="bo">Bolivia</option>
      <option value="br">Brazil</option>
      <option value="bs">Bahamas</option>
      <option value="bt">Bhutan</option>
      <option value="bv">Bouvet Island</option>
      <option value="bw">Botswana</option>
      <option value="by">Belarus</option>
      <option value="bz">Belize</option>
      <option value="cc">Cocos (Keeling) Islands</option>
      <option value="cd">Congo, The Democratic Republic of the</option>
      <option value="cf">Central African Republic</option>
      <option value="cg">Congo</option>
      <option value="ch">Switzerland</option>
      <option value="ci">Ivory Coast (Cote D'Ivoire)</option>
      <option value="ck">Cook Islands</option>
      <option value="cl">Chile</option>
      <option value="cm">Cameroon</option>
      <option value="cn">China</option>
      <option value="co">Colombia</option>
      <option value="cr">Costa Rica</option>
      <option value="cu">Cuba</option>
      <option value="cv">Cape Verde</option>
      <option value="cx">Christmas Island</option>
      <option value="cy">Cyprus</option>
      <option value="cz">Czech Republic</option>
      <option value="de">Germany</option>
      <option value="dj">Djibouti</option>
      <option value="dk">Denmark</option>
      <option value="dm">Dominica</option>
      <option value="do">Dominican Republic</option>
      <option value="dz">Algeria</option>
      <option value="ec">Ecuador</option>
      <option value="ee">Estonia</option>
      <option value="eg">Egypt</option>
      <option value="eh">Western Sahara</option>
      <option value="er">Eritrea</option>
      <option value="es">Spain</option>
      <option value="et">Ethiopia</option>
      <option value="fi">Finland</option>
      <option value="fj">Fiji</option>
      <option value="fk">Falkland Islands</option>
      <option value="fm">Micronesia</option>
      <option value="fo">Faroe Islands</option>
      <option value="fr">France</option>
      <option value="fx">France (European Territory)</option>
      <option value="ga">Gabon</option>
      <option value="gb">Great Britain</option>
      <option value="gd">Grenada</option>
      <option value="ge">Georgia</option>
      <option value="gf">French Guyana</option>
      <option value="gh">Ghana</option>
      <option value="gi">Gibraltar</option>
      <option value="gl">Greenland</option>
      <option value="gm">Gambia</option>
      <option value="gn">Guinea</option>
      <option value="gp">Guadeloupe (French)</option>
      <option value="gq">Equatorial Guinea</option>
      <option value="gr">Greece</option>
      <option value="gs">South Georgia and the South Sandwich Islands</option>
      <option value="gt">Guatemala</option>
      <option value="gu">Guam (USA)</option>
      <option value="gw">Guinea Bissau</option>
      <option value="gy">Guyana</option>
      <option value="hk">Hong Kong</option>
      <option value="hm">Heard and McDonald Islands</option>
      <option value="hn">Honduras</option>
      <option value="hr">Croatia</option>
      <option value="ht">Haiti</option>
      <option value="hu">Hungary</option>
      <option value="id">Indonesia</option>
      <option value="ie">Ireland</option>
      <option value="il">Israel</option>
      <option value="in">India</option>
      <option value="io">British Indian Ocean Territory</option>
      <option value="iq">Iraq</option>
      <option value="ir">Iran</option>
      <option value="is">Iceland</option>
      <option value="it">Italy</option>
      <option value="jm">Jamaica</option>
      <option value="jo">Jordan</option>
      <option value="jp">Japan</option>
      <option value="ke">Kenya</option>
      <option value="kg">Kyrgyz Republic (Kyrgyzstan)</option>
      <option value="kh">Cambodia, Kingdom of</option>
      <option value="ki">Kiribati</option>
      <option value="km">Comoros</option>
      <option value="kn">Saint Kitts &amp; Nevis Anguilla</option>
      <option value="kp">North Korea</option>
      <option value="kr">South Korea</option>
      <option value="kw">Kuwait</option>
      <option value="ky">Cayman Islands</option>
      <option value="kz">Kazakhstan</option>
      <option value="la">Laos</option>
      <option value="lb">Lebanon</option>
      <option value="lc">Saint Lucia</option>
      <option value="li">Liechtenstein</option>
      <option value="lk">Sri Lanka</option>
      <option value="lr">Liberia</option>
      <option value="ls">Lesotho</option>
      <option value="lt">Lithuania</option>
      <option value="lu">Luxembourg</option>
      <option value="lv">Latvia</option>
      <option value="ly">Libya</option>
      <option value="ma">Morocco</option>
      <option value="mc">Monaco</option>
      <option value="md">Moldova</option>
      <option value="mg">Madagascar</option>
      <option value="mh">Marshall Islands</option>
      <option value="mk">Macedonia</option>
      <option value="ml">Mali</option>
      <option value="mm">Myanmar</option>
      <option value="mn">Mongolia</option>
      <option value="mo">Macau</option>
      <option value="mp">Northern Mariana Islands</option>
      <option value="mq">Martinique (French)</option>
      <option value="mr">Mauritania</option>
      <option value="ms">Montserrat</option>
      <option value="mt">Malta</option>
      <option value="mu">Mauritius</option>
      <option value="mv">Maldives</option>
      <option value="mw">Malawi</option>
      <option value="mx">Mexico</option>
      <option value="my">Malaysia</option>
      <option value="mz">Mozambique</option>
      <option value="na">Namibia</option>
      <option value="nc">New Caledonia (French)</option>
      <option value="ne">Niger</option>
      <option value="nf">Norfolk Island</option>
      <option value="ng">Nigeria</option>
      <option value="ni">Nicaragua</option>
      <option value="nl">Netherlands</option>
      <option value="no">Norway</option>
      <option value="np">Nepal</option>
      <option value="nr">Nauru</option>
      <option value="nt">Neutral Zone</option>
      <option value="nu">Niue</option>
      <option value="nz">New Zealand</option>
      <option value="om">Oman</option>
      <option value="pa">Panama</option>
      <option value="pe">Peru</option>
      <option value="pf">Polynesia (French)</option>
      <option value="pg">Papua New Guinea</option>
      <option value="ph">Philippines</option>
      <option value="pk">Pakistan</option>
      <option value="pl">Poland</option>
      <option value="pm">Saint Pierre and Miquelon</option>
      <option value="pn">Pitcairn Island</option>
      <option value="pr">Puerto Rico</option>
      <option value="pt">Portugal</option>
      <option value="pw">Palau</option>
      <option value="py">Paraguay</option>
      <option value="qa">Qatar</option>
      <option value="re">Reunion (French)</option>
      <option value="ro">Romania</option>
      <option value="ru">Russian Federation</option>
      <option value="rw">Rwanda</option>
      <option value="sa">Saudi Arabia</option>
      <option value="sb">Solomon Islands</option>
      <option value="sc">Seychelles</option>
      <option value="sd">Sudan</option>
      <option value="se">Sweden</option>
      <option value="sg">Singapore</option>
      <option value="sh">Saint Helena</option>
      <option value="si">Slovenia</option>
      <option value="sj">Svalbard and Jan Mayen Islands</option>
      <option value="sk">Slovak Republic</option>
      <option value="sl">Sierra Leone</option>
      <option value="sm">San Marino</option>
      <option value="sn">Senegal</option>
      <option value="so">Somalia</option>
      <option value="sr">Suriname</option>
      <option value="st">Saint Tome (Sao Tome) and Principe</option>
      <option value="su">Former USSR</option>
      <option value="sv">El Salvador</option>
      <option value="sy">Syria</option>
      <option value="sz">Swaziland</option>
      <option value="tc">Turks and Caicos Islands</option>
      <option value="td">Chad</option>
      <option value="tf">French Southern Territories</option>
      <option value="tg">Togo</option>
      <option value="th">Thailand</option>
      <option value="tj">Tadjikistan</option>
      <option value="tk">Tokelau</option>
      <option value="tm">Turkmenistan</option>
      <option value="tn">Tunisia</option>
      <option value="to">Tonga</option>
      <option value="tp">East Timor</option>
      <option value="tr">Turkey</option>
      <option value="tt">Trinidad and Tobago</option>
      <option value="tv">Tuvalu</option>
      <option value="tw">Taiwan</option>
      <option value="tz">Tanzania</option>
      <option value="ua">Ukraine</option>
      <option value="ug">Uganda</option>
      <option value="um">USA Minor Outlying Islands</option>
      <option value="uy">Uruguay</option>
      <option value="uz">Uzbekistan</option>
      <option value="va">Holy See (Vatican City State)</option>
      <option value="vc">Saint Vincent &amp; Grenadines</option>
      <option value="ve">Venezuela</option>
      <option value="vg">Virgin Islands (British)</option>
      <option value="vi">Virgin Islands (USA)</option>
      <option value="vn">Vietnam</option>
      <option value="vu">Vanuatu</option>
      <option value="wf">Wallis and Futuna Islands</option>
      <option value="ws">Samoa</option>
      <option value="ye">Yemen</option>
      <option value="yt">Mayotte</option>
      <option value="yu">Yugoslavia</option>
      <option value="za">South Africa</option>
      <option value="zm">Zambia</option>
      <option value="zr">Zaire</option>
      <option value="zw">Zimbabwe</option>
    </select>
  );
});

CountrySelect.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool
};

CountrySelect.defaultProps = {
  required: true
}

export default CountrySelect;