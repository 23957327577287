const twoway = {
  sessionId: 'abxd',
  fares: [
    {
      sourceCode:
        'VWFsUkxycU00RSs0QVFRYldadmd5Q0pudllJbU1sN0xtMjRTcmxoMlUwTzhoRitLc0xpZHd2Yk05Z2N0V2w3aDY4ZVNwc25qOEhnKzNmazVFTWQ1ZStYWnRqYVlUSXl3ZGlHdno2REFLNGJYT2JHSWpqbFJXb0tPczRmZ21kU1oxMlR1T2phbmU5TWNZdzBab05UanJ3PT0=',
      directionIndicator: 'Return',
      baseFare: 648.96,
      totalTax: 924.13,
      perAdultTotalFare: 393.275,
      itineraries: [
        {
          totalStops: 0,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T19:59:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T22:57:00',
              journeyDurationMins: 178,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 0,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            }
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'VWFsUkxycU00RSs0QVFRYldadmd5Q0pudllJbU1sN0xtMjRTcmxoMlUwTzhoRitLc0xpZHd2Yk05Z2N0V2w3aDY4ZVNwc25qOEhnKzNmazVFTWQ1ZStYWnRqYVlUSXl3ZGlHdno2REFLNGJYT2JHSWpqbFJXb0tPczRmZ21kU1oxMlR1T2phbmU5TWNZdzBab05UanJ3PT1=',
      directionIndicator: 'Return',
      baseFare: 648.96,
      totalTax: 924.13,
      perAdultTotalFare: 393.275,
      itineraries: [
        {
          totalStops: 0,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T19:59:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T22:57:00',
              journeyDurationMins: 178,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            }
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T12:59:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T15:53:00',
              journeyDurationMins: 174,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'VWFsUkxycU00RSs0QVFRYldadmd5Q0pudllJbU1sN0xtMjRTcmxoMlUwTzhoRitLc0xpZHd2Yk05Z2N0V2w3aDY4ZVNwc25qOEhnKzNmazVFTWQ1ZStYWnRqYVlUSXl3ZGlHdno2REFLNGJYT2JHSWpqbFJXb0tPczRmZ21kU1oxMlR1T2phbmU5TWNZdzBab05UanJ3PT2=',
      directionIndicator: 'Return',
      baseFare: 648.96,
      totalTax: 924.13,
      perAdultTotalFare: 393.275,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T19:59:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T22:57:00',
              journeyDurationMins: 178,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-16T06:00:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-16T07:57:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T12:59:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T15:53:00',
              journeyDurationMins: 174,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'ZXdVYkRMWnh4Q3c0RXJucmVkZ0I3MnBzQ2g5WHY0WmdTMDAvcDVtdnZOSC90Rmd1OEFxZEhvQnVJc2NIaHFOZ1B6Mzk1RXRQTjdDY2lPdGh3ZkVTa01QTUlTMG90YUJKQzJKS2ZTaVYxemFqTnhHZy8vQUkxTGxvTFZITko2S1FLMVJLaE1sbG1VWTFjYmNDakxXcmR3PT0=',
      directionIndicator: 'Return',
      baseFare: 648.96,
      totalTax: 924.13,
      perAdultTotalFare: 393.275,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T19:59:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T22:57:00',
              journeyDurationMins: 178,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-16T06:00:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-16T07:57:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T15:00:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T17:53:00',
              journeyDurationMins: 173,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'Q2hpUkZ4TkpnbllKT2RRWDY5K21hRWJMRnlwckZvMHEwbkZpY0dUNENGbXpVdFpLTytKNkYyZlVOUTg0MDFwemM3ZXFUWHI4dS90dGN6VHN6SHE1Y2ZYcENROVM1akl4OGFYbnhUYkQvc3NwSVhXSjY5Qkc1T1RGNFNRdW5RaEc2UnNiQTVpWUtJY1NXakRLdkRoY1VBPT0=',
      directionIndicator: 'Return',
      baseFare: 648.96,
      totalTax: 924.13,
      perAdultTotalFare: 393.275,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T10:59:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T13:56:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T15:00:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T17:53:00',
              journeyDurationMins: 173,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'NVNUdktUQW9NUVpvUnpoVnpNdE85ckF6V09PNVVDd0dPcVZ5U3FhRHJhb2hkKysya3NKaG94b296SlhkOENaNFJnN1lYRitTdkRZZlpDUUdNb1pldHdEcjNPa29vdTJSdE1TQkdaek5URXZMNkFabnBmckxUbXZkRmFxOVNkei9xWjBhci9JR1JkZmNSanRwTEp4cHVnPT0=',
      directionIndicator: 'Return',
      baseFare: 648.96,
      totalTax: 924.13,
      perAdultTotalFare: 393.275,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T10:59:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T13:56:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T12:59:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T15:53:00',
              journeyDurationMins: 174,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'WUJqMjhPRjhLYjhMTHlYcmtGcEdNOTMwTDVSajQwR3ZHbWt1Q1AvWCttWGFWOHI1TjJSSnR6N2lNOXRQNmlCbWorYUhMaWE2V2gvbFJ5a2NFTlRTU2gvRDJ5a2ZqSUZhM3lrZVNZL3pBOVVDZHd5UDlhOFlVTW43aDZYMzRJOVRsZVowYnB4ckx4STBRVHRnNENOOTR3PT0=',
      directionIndicator: 'Return',
      baseFare: 648.96,
      totalTax: 924.13,
      perAdultTotalFare: 393.275,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T09:29:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T12:26:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T12:59:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T15:53:00',
              journeyDurationMins: 174,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'ZFB2amtFeHFRVzM2NlFVTkZ0L2Q1ejhuZDB3SzQ2MUpwNWFERWFONUZaaXZDWmVYSWNzZlJEZ2Q0RVFiYmlzc1ZmZjl2c3ZWamZjUHF4VWZnT0VZVi9VZ0dJUFR0aTBMOVJJYjZNejNKNWxPbVNrV1lrUFhhdGUrclhzRjY5L0dmdUgycWQrRHNKOEwzdHo0RlRDYkNnPT0=',
      directionIndicator: 'Return',
      baseFare: 648.96,
      totalTax: 924.13,
      perAdultTotalFare: 393.275,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T09:29:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T12:26:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T15:00:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T17:53:00',
              journeyDurationMins: 173,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'aStyZ29GYVdMa2QzNVJQZXpSYmxpbWgrbkQyUkk5VlVmZStTRms1M2w4RDMyRmkrWTdmYVJmZjY1VFpoSGs2Nm5EVC9mVU8wMktxaE9EWUgrM3B3c1hQQWZWQVZWTVFvRkR3OWFRZmhWdWZrOHNnNU9seXgwOVlXTWRWakVEelpjL1FTQzc3aUx5bktoYTZDRi9YQ21nPT0=',
      directionIndicator: 'Return',
      baseFare: 868.03,
      totalTax: 771.94,
      perAdultTotalFare: 409.9925,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T09:05:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T12:05:00',
              journeyDurationMins: 180,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T14:50:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T17:45:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'QmhjSmcwZURFTUFuc3E1bUgxZTNCdXdlNHI1Qko2c3NzQUROQzdGZ3ZRODFOYWZkYlh2a0c3T1I2VXh3K2tpQU0wUU9tdGQvUXFFY0FHVjFqYXFkeEZ0TnZzOHNwSGFsQUxiWnQzVERnSFU5U0o3b2dFRC91WVpUeUFVTjhlTWxBazRhMDk0a2dWdkR4NUFVV295L1lRPT0=',
      directionIndicator: 'Return',
      baseFare: 868.03,
      totalTax: 771.94,
      perAdultTotalFare: 409.9925,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T09:05:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T12:05:00',
              journeyDurationMins: 180,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T11:10:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T14:09:00',
              journeyDurationMins: 179,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'b2YxVU1Lcmo5Vzl6dzlHeFFCRmdlYk9ZQjRLNHBoZERVM0lTMzhGWElGd2l1MjlWNVBiRlE3ZVh1ek1Ec2VvaDB4OWlyNUYxYzJRQXcrR0xMSW9oWE94RElJem5rQkxna2RNaFFYQWwxZThubENFZENoTEFlUXdwVlNTZi9HMnB5QytQNkw2OXk5R1BBZlljbGJXTUpRPT0=',
      directionIndicator: 'Return',
      baseFare: 868.03,
      totalTax: 771.94,
      perAdultTotalFare: 409.9925,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T06:00:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T08:55:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T12:30:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T14:34:00',
              journeyDurationMins: 184,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T11:10:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T14:09:00',
              journeyDurationMins: 179,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'd0QwN2Q5OGludFRTRk9tKytIZDgxbTAvMyttdDJqQ1dwOCsveS9BU0tLbTRCOGI1NVZEWk94REFDMFY0YlQrajIycWtGNmRneVI3U1Y2QVA2T1dDY2FvTXhpMHJnWmh5MHNCYzNJSkpRUkN1eDRab080aDltdUxDdkRvNzNtREpzendKSlgrYm9aZm85akE0L01FTXpBPT0=',
      directionIndicator: 'Return',
      baseFare: 868.03,
      totalTax: 771.94,
      perAdultTotalFare: 409.9925,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T06:00:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T08:55:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T12:30:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T14:34:00',
              journeyDurationMins: 184,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T14:50:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T17:45:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'NWR2cDlQVVN2VTdwazhzbS8wOG9WTUZuSTluMVlQeDlrdThacXlFc3lUcFgxa05rcUlDYWxzQ3FicHh2MHBnMHkyQVd2RDh6Ulladnl0YXgrSGF5WVJ0MzVET1dyWnVEblV3TU8yTWZQa0VVNjZQajVocXkzY0x2L3VwOFNQNjB3QlNRS0cwdGpmdzBOZzU5bkpLRXpBPT0=',
      directionIndicator: 'Return',
      baseFare: 814.28,
      totalTax: 929.59,
      perAdultTotalFare: 435.9675,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T16:53:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T20:01:00',
              journeyDurationMins: 188,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T12:59:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T15:53:00',
              journeyDurationMins: 174,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'VnJGRGRta3dUTHFRc0FQbGdXNUVVNE5UWkp0ZTYxOFlJREtRRWdQRmpLUFRwc0REdk0vT0p6eVRsMVAwK2VjbStiYWtZTlFoUktmOW12SUtYSVNyVVJKUlhRQm8zQWN3Q0dQWmNmREt2UGVXMmR4dDc2M2cyM3JNdCtRa2pFRkMrMk51WG9PMHV2L0hRMExpQllPL1V3PT0=',
      directionIndicator: 'Return',
      baseFare: 814.28,
      totalTax: 929.59,
      perAdultTotalFare: 435.9675,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T16:53:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T20:01:00',
              journeyDurationMins: 188,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T15:00:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T17:53:00',
              journeyDurationMins: 173,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'ejdmblFGcXFPTEtzNWlScStGeDNMc1ZDZDFUMHlKa2xpdEpzVDhRNTBUZzN3TzhocVlDYVdxUlM0NFpVSjkyd0VZWFpIc2dRZGdpVGZIZmM0dlQyWkp5eklyQlhOZWQwWjU3ek5MRVdoU1ZEWURRK3dZcFVNQjRXenR4c0Y2OWltQnlucWVDa291dGQ3YzdPcTZadUF3PT0=',
      directionIndicator: 'Return',
      baseFare: 979.6,
      totalTax: 775.61,
      perAdultTotalFare: 438.8025,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T11:35:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T14:42:00',
              journeyDurationMins: 187,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T11:10:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T14:09:00',
              journeyDurationMins: 179,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'cU9tckZza25wd29UWmxSMHgxTjNsUXZvZXlDdXZHdXJTWFA5bU01UWtnMXEvUW12VGo5SExYWWhncmIrbUh0OGxUOFVIeXBzTzhZa1ROaUFHSmJwTTBUR01pM01lZXMydHgrbHpMODErUGJWQkJFQU5ORDMzbzJDWnJoU2NUN3hWczU3ZVdKRUU2VUdtYnJEb3hTaFJnPT0=',
      directionIndicator: 'Return',
      baseFare: 979.6,
      totalTax: 775.61,
      perAdultTotalFare: 438.8025,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T11:35:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T14:42:00',
              journeyDurationMins: 187,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T14:50:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T17:45:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'enM5MzZrRFZaN1RCTzBWTjh2Y2NTckpDTk1JcFhtd1QwWis5WWtlenkySFVybmtHbjVyeDdlK3IybjJvSUNXWjRxaW9tYWZOT1M5eEdlenRSQm9TK3hQa2R1UEhYVjdoYWlweG1DdFMrTm05cUFXaU8wVFkzUEdZQm9od2VPVm9JQzZRSTV2b2FkenZSWDBFNGErQTVBPT0=',
      directionIndicator: 'Return',
      baseFare: 958.98,
      totalTax: 934.36,
      perAdultTotalFare: 473.3375,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T09:29:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T12:26:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T09:51:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T13:34:00',
              journeyDurationMins: 163,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T20:57:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T23:33:00',
              journeyDurationMins: 156,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'UEhlY1FtT3JRZFhyKzVqTjM3RGY3VTcxYzdjNk9UbXd1UjZwSGI4TDlZNURmS3NPM0d1eFpKc0U4NUllUG1yOEtsWHlLQ056Ri9Zd0VGOEVyck9CeEFQQklYSjAvK3IvWERYeTZkN2tuL0NnWUF2N1hSN05zblh1YlB0WjNkRzJReTZ3ZG9PSTRDMStNNW9mVTZHZmpnPT0=',
      directionIndicator: 'Return',
      baseFare: 958.98,
      totalTax: 934.36,
      perAdultTotalFare: 473.3375,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T10:59:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T13:56:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T11:03:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T14:52:00',
              journeyDurationMins: 169,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T20:57:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T23:33:00',
              journeyDurationMins: 156,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'K2RzZUJlV09Nem4vYXI3c2VxcUEyY090TTVTYVNVK2Y5TE1jeEhtQmpzYi9iUVh5eXNjckZsQjkrRVJXZmhxK0F6dTNmUEYyQWFPdGxqOUR2czR4SVNTVSt1RWl0UytpS2RpTkI5YzkwTFViU0ZNYWV0UUJqM2l5SldOb0FUSTgxZTB6TVZRL3RPeW1kR3N1ZU1VTWVRPT0=',
      directionIndicator: 'Return',
      baseFare: 958.98,
      totalTax: 934.36,
      perAdultTotalFare: 473.3375,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T19:59:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T22:57:00',
              journeyDurationMins: 178,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-16T06:00:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-16T07:57:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T09:51:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T13:34:00',
              journeyDurationMins: 163,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T20:57:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T23:33:00',
              journeyDurationMins: 156,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'Rkw0amwwSVhoRXdCbE9aTzJZbC95M3pUZm85VmttamtvdDJHaSt0MWtvcjNxSHFFZHpyejQvU1ZGWUJ5dmJKbTJwbERXbUc2L3ZsR3FwZncyVFNBenRuR1RIQ0lqKytLRUE1Q3R1R0NoU3ZJc0ZML0xEZ1M5bDEwMlQvV3U0b2R2TU55YUNxdG9UTThBU0VIL0xoT0N3PT0=',
      directionIndicator: 'Return',
      baseFare: 958.98,
      totalTax: 934.36,
      perAdultTotalFare: 473.3375,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T10:59:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T13:56:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T09:51:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T13:34:00',
              journeyDurationMins: 163,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T20:57:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T23:33:00',
              journeyDurationMins: 156,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'T2t2NFZ6RUVGRkQyNkJ3NjVFZG1HSE9JR1JTK3M3RHJzS1ZPNnN6TnVNMjB5N0pWWUxUakFjWmxZTVVxdllFZjIyL0pEMWpDMWZmYjE4bE5jeS9PZHY2Vzk0aWpDbHZrVjZCMXlWeklnREEzMi93Zk1JbzY5c05laFJiVE9KcUVabmhaMlF4eDRIcE9uTXptMVRlZWtBPT0=',
      directionIndicator: 'Return',
      baseFare: 958.98,
      totalTax: 934.36,
      perAdultTotalFare: 473.3375,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T19:59:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T22:57:00',
              journeyDurationMins: 178,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-16T06:00:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-16T07:57:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T16:04:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T19:59:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T20:57:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T23:33:00',
              journeyDurationMins: 156,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'aHdBZDdoQ3c4UmdwdC91T1p0Zzc3M1dRZEEzRDA0ekN1VVlPRHR5Q1dlNVdYRExhS3RMYi8wTm1mRFFJZGRwMk5lQVhST1A1ek5ienpmMDdmaXRhUTRkMDlENUdlT3NJdU8vOTBycjZneWFHZFZrVUpIV1FFOWRTVUljelNsSm1yVWhLQkpjajB2SjY4QzdiWTVDSWVnPT0=',
      directionIndicator: 'Return',
      baseFare: 958.98,
      totalTax: 934.36,
      perAdultTotalFare: 473.3375,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T19:59:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T22:57:00',
              journeyDurationMins: 178,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-16T06:00:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-16T07:57:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T11:03:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T14:52:00',
              journeyDurationMins: 169,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T20:57:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T23:33:00',
              journeyDurationMins: 156,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'MEgzeHowcUlLVTJ3eXZMY3R5SUVxblk3bTExN0ttRXNoWUxsNjFSZ0pnRFcvNU5JWWRhMUxwMmJRM240UDZ6NDFsalJ4cDBqenIraUVNOVZ3ZjRKcjVvUXNhUURtLzZJclVrYlZkNUVjWitsUDNnTHhXeWhITmJGZWxvdUIvY0VMUmFMR1FOdGQ3cmhuVGtHLzZTRU5nPT0=',
      directionIndicator: 'Return',
      baseFare: 958.98,
      totalTax: 934.36,
      perAdultTotalFare: 473.3375,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T09:29:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T12:26:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T11:03:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T14:52:00',
              journeyDurationMins: 169,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T20:57:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T23:33:00',
              journeyDurationMins: 156,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'VWNJSnNDUmZ6RzhHR3FadTFxTEJPanlFVkJuaFJGOFgxZkk3ZVZQRGp6Tit3UU5LY05ZK3dLdDkrQVpHNEtRdWFPa3lJM2RvMFpVQ0xqTlNvVDZVUVhBR2YwZ0JwQXQ2eUtUcE5PMzNkdVlYR1lqb3duRXFyUS9kaTNMOWg5dEVZbjFmQk1BZStZWklXcWpiUHV6NlFRPT0=',
      directionIndicator: 'Return',
      baseFare: 958.98,
      totalTax: 934.36,
      perAdultTotalFare: 473.3375,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T10:59:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T13:56:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T16:04:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T19:59:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T20:57:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T23:33:00',
              journeyDurationMins: 156,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'VENyOEYzSEYwQ1BqUFZSeERzWXQwMTN2ZERGRU1ybEtGLzJBTmRDdXJkT3ZOMXdHb1ZHajE1TVJVeEQ5K0VvV2NRTmc1N0pBOE80cTdkMzBtcXhvc2JwcUNwYkxjVjdoM3Nnd2diNWJoQzh4a1VQTjJLclRKOGg0eTM0Z0pyaXdkRDF3QVZRUXhnT29hSmk2VlU4UFVBPT0=',
      directionIndicator: 'Return',
      baseFare: 958.98,
      totalTax: 934.36,
      perAdultTotalFare: 473.3375,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T09:29:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T12:26:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T16:04:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T19:59:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T20:57:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T23:33:00',
              journeyDurationMins: 156,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'SU9LdEtJQlFyc3h5cXoyTzlDV2ZwU2puOHRESEs4VTZhSEh2cVc5SFk2VDFmWFNaVDllbzlKMWx2aGIvR3RMUVVCQ2ZMcDJMY1htd0dsUzNyRU5zdXpxYjFFMzhuOElhdU9jTWRkQkN4NUw2WWVrMFRWMGhTVS9wVVk1TjJNRjg5aVp4S2hoNktTNFVzV2hzWDJBdDV3PT0=',
      directionIndicator: 'Return',
      baseFare: 1124.3,
      totalTax: 939.81,
      perAdultTotalFare: 516.03,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T16:53:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T20:01:00',
              journeyDurationMins: 188,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T11:03:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T14:52:00',
              journeyDurationMins: 169,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T20:57:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T23:33:00',
              journeyDurationMins: 156,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'Sm40QWZlYno3VzNpYnk5T3Z5ZlF5KzBxOG13bDVmaDhDS0lCd0tFSUs5NklRNjBpTjZYTjBTa0dEREFsQmFtbFR3T2ZFb0pVbkdHbGZVelhmaThvNERGYzZPWjR1a3A5dmpjRHNQejUxdVcrSXBRNzcrdlNDN1pGT0NUeldWQWQvbHJaODRLUjJISHdVQngwdnNLV2hBPT0=',
      directionIndicator: 'Return',
      baseFare: 1124.3,
      totalTax: 939.81,
      perAdultTotalFare: 516.03,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T16:53:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T20:01:00',
              journeyDurationMins: 188,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T16:04:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T19:59:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T20:57:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T23:33:00',
              journeyDurationMins: 156,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'b09obyt4bVV1b3JiUVpLRFM5ZTJuMWluRkpjanluYTRFZFBJQ1ZIUld6bzRSM0lsRkx3N2RobVBKQzlIWTNxSE53OXdjcDM4Si9MZHRrZmg5WU5oeFJlVHJaRHhvb2xmSVlZRFN6YjRxT3hTeWFBSjQzemd0WGhlOWxDay8wWEJQbXBMeFhUc2l1aUx6OUtwOVZJU1ZRPT0=',
      directionIndicator: 'Return',
      baseFare: 1124.3,
      totalTax: 939.81,
      perAdultTotalFare: 516.03,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T16:53:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T20:01:00',
              journeyDurationMins: 188,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T09:51:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T13:34:00',
              journeyDurationMins: 163,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T20:57:00',
              arrivalAirportLocationCode: 'LGA',
              arrivalDateTime: '2022-12-22T23:33:00',
              journeyDurationMins: 156,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'MEpPbnJpSnV6US9jUnYySnFzM3VnbFNyOE80K3lJVXdaUDVJRnBEUHR4dFAyb1JXQk05TktBUUZ0c1kwd25tVmhidWZCaHJBTDJVTzdSejJjQW9CUDE3S2IxenQ1UVRyZFNiYnU0Y3RQcE5YWGVvSUJybEVBYjNsU2s0aXFFaythQ2UvL3lOOENTRkVqVmpmSG5ySFlBPT0=',
      directionIndicator: 'Return',
      baseFare: 1289.62,
      totalTax: 785.85,
      perAdultTotalFare: 518.87,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T15:05:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T18:13:00',
              journeyDurationMins: 188,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T14:50:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T17:45:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'ZzBlaFprZlRlaVRKNzIvOGF1R3YvZlVvSU5PUVRKMm13L0lkUGVRK1FjNnVSWXJrZnZJZlZETEE0SFZUalNVMWNReW5VSkNJS1dSa2Q0bU1UOXYyWVEwZWErVGpCY1Vhd21rNjcydnVocFJvUXlHUFV0VW10ZnBrOVcxdGNnTW9ZZTc4Nkk3RURNb28zQU5wOEFxZGZRPT0=',
      directionIndicator: 'Return',
      baseFare: 1289.62,
      totalTax: 785.85,
      perAdultTotalFare: 518.87,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T15:05:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T18:13:00',
              journeyDurationMins: 188,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T05:10:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-22T09:16:00',
              journeyDurationMins: 186,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-22T11:10:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T14:09:00',
              journeyDurationMins: 179,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'WmN0YUREaGZTWlM5UEV2SjI3MmlQQW00cXoxUWRTNTdVWXFPZEZGRlg5R2lkcWhwa29DTFF3WFpJZ0VWNk5kNnRYaEZLME9NeGFpM2Fydnh5c2hLUFhwbEJvNWxmMm5sWjdKakl0c20rWGRKQ0lEbXo2UkEyNzB6ajIzL1QrMFZPU0VidXdHWDFRek8xTTc4V1hLV1B3PT0=',
      directionIndicator: 'Return',
      baseFare: 1463.24,
      totalTax: 951,
      perAdultTotalFare: 603.5625,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T09:05:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T12:05:00',
              journeyDurationMins: 180,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T11:03:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T14:52:00',
              journeyDurationMins: 169,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T18:20:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T21:02:00',
              journeyDurationMins: 162,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'RVB3UHlYUTEzT3IyZ0FGRlNXQUF4ZUJXeHg5UU9sbm5yelVhT2lTcUUrV2o1aTJoZVAxOGJaTlNCMlNoa2hUMXZrZmhTN1V4U3BjeGpXQ0FPSCt2TGlPR0RoNW1tUmRZcjJzckk0RWZyRDVKYlJXbG5JRm9NekVXV0M1THpNc0hhMjFRYTh2NytrQVNFMWEyNjh3bDVnPT0=',
      directionIndicator: 'Return',
      baseFare: 1463.24,
      totalTax: 951,
      perAdultTotalFare: 603.5625,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T06:00:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T08:55:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T12:30:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T14:34:00',
              journeyDurationMins: 184,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T11:03:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T14:52:00',
              journeyDurationMins: 169,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T18:20:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T21:02:00',
              journeyDurationMins: 162,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'MXNYK0JkUHB0bWtubUlxcFA4UFVMVnRSeTJmQ1FKeHNzU0M4QzJTY2ZISnBma0lVQXBHQVN3VHVSbTNKQUNrakpWL2dtMGFpbGlkZFMzS0hLZWxDckZ4WmFudFhPRUloVDB6bjdWOEg5bWgzbDM1NkdzTE5LSmNsREViOW1BUzNQSjd6RXdzenJSL0cyM05GNmxteWZBPT0=',
      directionIndicator: 'Return',
      baseFare: 1492.2,
      totalTax: 951.96,
      perAdultTotalFare: 611.04,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T09:05:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T12:05:00',
              journeyDurationMins: 180,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T09:51:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T13:34:00',
              journeyDurationMins: 163,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T14:36:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T17:15:00',
              journeyDurationMins: 159,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'RmFDNy82MWNndWs3SE5ONHprR2wyOUNYbWN1bSt1bWt6WkN1Z3FGeWNXeU5ndS9sczFWY3NkYWdPVDVuM2FQKzNvUG9QZ3JSWENCVm5JV1ZQcnlUZ0pmc2pXQmJobmh1VmVIMjNtRTlKZUhmZzFSSCtZMVhwSk1MNE85cGg4NUs5K3BzLzBhWnlkS3pmUHVkLzRjYmRnPT0=',
      directionIndicator: 'Return',
      baseFare: 1492.2,
      totalTax: 951.96,
      perAdultTotalFare: 611.04,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T06:00:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T08:55:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T12:30:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T14:34:00',
              journeyDurationMins: 184,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T09:51:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T13:34:00',
              journeyDurationMins: 163,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T14:36:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T17:15:00',
              journeyDurationMins: 159,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'MkZkY0VCSHNUdHo4bjRrMnBuUXZWTFl0c2VFNmV6RXdNNXN4c0dnazNFdm9OUmNlWGVBdmJWdC9wRDJQdUp4ZTJkU3JFM0IzMFpmckI4cVE3bWx3OGcyeXgvTmg0cEkwekVlVTNHSVJDZ0J2NEdGOXFBV3ZrQ2JZR2ZYTWg5OEZVNjVaa3U1V0lqd2l3VHdxaHljRlV3PT0=',
      directionIndicator: 'Return',
      baseFare: 1574.81,
      totalTax: 954.68,
      perAdultTotalFare: 632.3725,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T11:35:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T14:42:00',
              journeyDurationMins: 187,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T11:03:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T14:52:00',
              journeyDurationMins: 169,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T18:20:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T21:02:00',
              journeyDurationMins: 162,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'T2FXMERXTmZmTlVXY0tCTUNNdCtEeC9ReSsxM0d4aERUSXFENDdHVGxHL1pJYnBTZ0RQem51bGNtTDAxbHdPcm1oOXZFZUs2ejBZSVpQdis0RWU1b050a0VEMzFuNnhHSThMY2NVaGxXUjRIeUdnd0ZueFJhOHRjWGZESlU2SEcwRmJDUmI5dXhaTDN4ZlpVdnowUkVRPT0=',
      directionIndicator: 'Return',
      baseFare: 1603.77,
      totalTax: 955.63,
      perAdultTotalFare: 639.8525,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T11:35:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T14:42:00',
              journeyDurationMins: 187,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T09:51:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T13:34:00',
              journeyDurationMins: 163,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T14:36:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T17:15:00',
              journeyDurationMins: 159,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'Qk9DM2NBZVBZT2RrRnViMnd3aUt6aTNGK3NvU05VTi91a0pxUm1GSnVHYVhZa1p4Y0szbzVna1JocHg2TGlPN0FLNEY5aFdhQTFqdFR5dkVnUHVZcUhPQkUxUHpTR25CdHhnYTN6cTV2MSsvN3J4WUh1WEFmNFJMemZSTjJQSFo5QldIM0ZxV0dUcFRnYUNIMDVFNzVnPT0=',
      directionIndicator: 'Return',
      baseFare: 1657.52,
      totalTax: 957.42,
      perAdultTotalFare: 653.735,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T06:00:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T08:55:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T12:30:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T14:34:00',
              journeyDurationMins: 184,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T09:51:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T13:34:00',
              journeyDurationMins: 163,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T18:20:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T21:02:00',
              journeyDurationMins: 162,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'NVFsQm9zcGt4emRWWFNBUlllWk9rTVhtcklYZDFmd3RwNC9BZklySmNIMmlmZkJQUUlmbVYvYnN5WTRuSGxpckFpRS9HMCtES0xUSHF4cXUvVXljMnc5aG9URnlKZCtOZ0VSQ1BSd0hSR2kvY3R1SEF0U2lyQXdtdE0rS1B5eFIyVWIxd3lLejJyaDZUaXJSdHVBbXJBPT0=',
      directionIndicator: 'Return',
      baseFare: 1657.52,
      totalTax: 957.42,
      perAdultTotalFare: 653.735,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T09:05:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T12:05:00',
              journeyDurationMins: 180,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T09:51:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T13:34:00',
              journeyDurationMins: 163,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T18:20:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T21:02:00',
              journeyDurationMins: 162,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'RE5hb1l1Snh4SHlaNVN6R01RSk5BTTRGQW9XWlBrWlVKdG5UeU56LzJkaE03UUFCcjdXTUpkOXBMdmJEUUh6TkpWVHEvYU10TGxmcmxDaURrYTZ4UU5LZnowRFRyR3BaVjduNnFVQ0FPOFJmT2RPZHBDT2ZFQmgxR0pPVmZRc2F5NWUyczlsVmx0QlJOOTR3eG5MbWZRPT0=',
      directionIndicator: 'Return',
      baseFare: 1769.09,
      totalTax: 961.08,
      perAdultTotalFare: 682.545,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T11:35:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T14:42:00',
              journeyDurationMins: 187,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T09:51:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T13:34:00',
              journeyDurationMins: 163,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T18:20:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T21:02:00',
              journeyDurationMins: 162,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'Q0cvRHpuWFYvM2ZiWVVtQm01bHJmZ3FxTEpkU3Bxa3MzMTFkY20xVjl2dE5CdVV4STh1N3UrSExwVUZRQXMzT1djOC93KzVpY0svekt4WVlqbzl1SWY1RHVQeUc1V1p5clBqYUY2YXVZVndBa3BJZitXOFlIWlJRSElzNU1ieE9nZzRxZG53NU1KSmNHMW5PM3NwRjd3PT0=',
      directionIndicator: 'Return',
      baseFare: 1884.83,
      totalTax: 964.91,
      perAdultTotalFare: 712.4375,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T15:05:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T18:13:00',
              journeyDurationMins: 188,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T11:03:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T14:52:00',
              journeyDurationMins: 169,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T18:20:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T21:02:00',
              journeyDurationMins: 162,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'Si91VjVyelNlc2MwMjVGMk8vRHc1U3ZHUmlhMkRHVDZ3TEtidSsxeEwxYmZoZ1Q3aWZ2QXc4MTBnUi95VHo4NmRUU3NiOFBGeEJkbjZKTlpSUzRIQUR5Rys5L1VZemVWYnV2OXpHOWwzc0lLcFNGblBHRGhDRGVIM2ZrdklUNDg5cXFqbG1DeHZ0U2QrL085bW5ic3dBPT0=',
      directionIndicator: 'Return',
      baseFare: 1913.79,
      totalTax: 965.87,
      perAdultTotalFare: 719.9175,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T15:05:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T18:13:00',
              journeyDurationMins: 188,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T09:51:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T13:34:00',
              journeyDurationMins: 163,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T14:36:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T17:15:00',
              journeyDurationMins: 159,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'L0MrRExraEloSlgwR01ITlI3allmY3pXaTQ5NlRFRVNUa05OWnBhTUNMMy8wV0NjMmhWY1Irclh5UjFLYW1EY1ExTWlLdTkxeVFwTWExM3lad3RNOFkxRFFBcmdKMWFObFE1aWF0Z2tVU1A4Qjl4cW9aVlVBb0MzZlkvU0kwb3Fjb0xsc2xXbnUxSkZYbG96blNOU2dBPT0=',
      directionIndicator: 'Return',
      baseFare: 2079.11,
      totalTax: 971.32,
      perAdultTotalFare: 762.6125,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T15:05:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T18:13:00',
              journeyDurationMins: 188,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'ORD',
              departureDateTime: '2022-12-22T09:51:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-22T13:34:00',
              journeyDurationMins: 163,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-22T18:20:00',
              arrivalAirportLocationCode: 'EWR',
              arrivalDateTime: '2022-12-22T21:02:00',
              journeyDurationMins: 162,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
  ],
};

export default twoway;
