import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import {Helmet} from 'react-helmet-async';
import {useHistory, useLocation, Link as RouterLink} from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
// import isPassportNumber from 'validator/lib/isPassportNumber';
import isFuture from 'date-fns/isFuture';
// import differenceInYears from 'date-fns/differenceInYears'
import {composePageTitle, getHomeLocation, getLocation} from './Common';
import FlightOverview from './components/FlightOverview';
import Header from './components/Header';
import {
  roundFormatMoney,
  getDateFromParts,
  tryParseDateOrNull,
  parseDate,
  dateToDateOnlyISO,
} from './Util';
import {Endpoints, Months, PageUrl, QueryKeys} from './Constants';
import Application from './config/application';
import useSnackbarAlert from './hooks/useSnackbarAlert';
import range from 'lodash-es/range';
import clsx from 'clsx';
import axios from 'axios';
import Footer from './components/Footer';
import {useQuery, useQueryClient} from 'react-query';
import {fareValidateFetch} from './api/fetches';
import CountrySelect from './components/CountrySelect';

const CustKeys = {
  EMAIL: 'email',
  MOBILE: 'mobile',
};

const PaxKeys = {
  TITLE: 'title',
  FNAME: 'firstName',
  MNAME: 'middleName',
  LNAME: 'lastName',
  MONTH: 'month',
  DAY: 'day',
  YEAR: 'year',
  GENDER: 'gender',
  PASSPORT_COUNTRY: 'passportCountry',
  PASSPORT_EXPIRY_MONTH: 'passportExpiryMonth',
  PASSPORT_EXPIRY_DAY: 'passportExpiryDay',
  PASSPORT_EXPIRY_YEAR: 'passportExpiryYear',
  PASSPORT_NUM: 'passportNum',
};

const PaxKeysAlwaysNotRequired = [PaxKeys.MNAME];

const PaxPassportOnlyKeys = [
  PaxKeys.PASSPORT_COUNTRY,
  PaxKeys.PASSPORT_EXPIRY_DAY,
  PaxKeys.PASSPORT_EXPIRY_MONTH,
  PaxKeys.PASSPORT_EXPIRY_YEAR,
  PaxKeys.PASSPORT_NUM,
];

const TRAVELER_TYPE_KEY = {
  A: 'Adult',
  C: 'Child',
  I: 'Infant',
};

const getTravelerObjKey = (travelerTypeKey, travelerNo) => {
  return `${travelerTypeKey}_${travelerNo}`;
};

const getTravelerLabelFromObjKey = (travelerObjKey) => {
  return travelerObjKey.replace('_', ' ');
};

const getTravelerTypeFromObjKey = (travelerObjKey) => {
  return travelerObjKey.split('_')[0];
};

const getTravelerTitleOptions = (travelerTypeKey) =>
  travelerTypeKey === TRAVELER_TYPE_KEY.A ? (
    <>
      <option value="Mr">Mr</option>
      <option value="Miss">Ms</option>
      <option value="Mrs">Mrs</option>
    </>
  ) : (
    <>
      <option value="Miss">Ms</option>
      <option value="Master">Master</option>
    </>
  );

const INITIAL_STATE = {
  cust: {
    [CustKeys.EMAIL]: '',
    [CustKeys.MOBILE]: '',
  },
  pax: null, // Object of Adult_1: {PaxKeys -> Value, ...}, Child_1: {PaxKeys -> Value, ...}, .....
};

const INITIAL_ERROR_STATE = {...INITIAL_STATE, cust: {...INITIAL_STATE.cust}};

const useStyles = makeStyles((theme) => ({
  textField: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    fontSize: '1rem',
  },
}));

const Booking = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [fare, setFare] = useState(location.state?.fare);

  const {data: validatedFare} = useQuery(
    [QueryKeys.FARE_VALIDATE, location.state?.sessionId, location.state?.fare.sourceCode],
    fareValidateFetch,
    {
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      enabled:
        Boolean(location.state && location.state.sessionId && location.state.fare),
    }
  );

  const [state, setState] = useState(INITIAL_STATE);

  const [error, setError] = useState(INITIAL_ERROR_STATE);

  const [submitted, setSubmitted] = useState(false);

  const [, snackbarAlert, setSnackbarAlertError] = useSnackbarAlert();

  const getPax = (total, type) => {
    const pax = {};
    for (let i = 1; i <= total; i++) {
      pax[getTravelerObjKey(type, i)] = {
        [PaxKeys.TITLE]: '',
        [PaxKeys.FNAME]: '',
        [PaxKeys.MNAME]: '',
        [PaxKeys.LNAME]: '',
        [PaxKeys.MONTH]: '',
        [PaxKeys.DAY]: '',
        [PaxKeys.YEAR]: '',
        [PaxKeys.GENDER]: '',
        [PaxKeys.PASSPORT_COUNTRY]: '',
        [PaxKeys.PASSPORT_EXPIRY_DAY]: '',
        [PaxKeys.PASSPORT_EXPIRY_MONTH]: '',
        [PaxKeys.PASSPORT_EXPIRY_YEAR]: '',
        [PaxKeys.PASSPORT_NUM]: '',
      };
    }
    return pax;
  };

  // Listen to validateFare changes and update fare/invalidate listing..
  useEffect(() => {
    if (!validatedFare) {
      return;
    }

    // If booking partially, don't do any validation.
    if (validatedFare.noFullBooking) {
      return;
    }

    const {valid} = validatedFare;

    if (!valid) {
      // Fare isn't valid anymore. Invalidate search result, show a message and go back to listing.
      queryClient.invalidateQueries(QueryKeys.LISTING);
      setSnackbarAlertError(
        'We are sorry but the fare you selected is not available anymore. Please select another one from' +
          ' the refreshed list of currently available fares. Redirecting to search...'
      );
      setTimeout(() => history.push(location.state.location), 5000);
      return;
    }
    // When fare is valid, use the updated fare. For now don't notify customer of any potential fare changes
    setFare((f) => {
      // Assign the extra properties
      const newFare = validatedFare.fare;
      newFare.pax = f.pax;
      return newFare;
    });
  }, [history, location, queryClient, setSnackbarAlertError, validatedFare]);

  useEffect(() => {
    if (!fare || !location.state?.sessionId) {
      history.push(location.state?.location || getLocation(PageUrl.HOME));
    }

    // Create pax object for states
    const {adults, children, infants} = fare.pax;
    const pax = {
      ...getPax(adults, TRAVELER_TYPE_KEY.A),
      ...getPax(children, TRAVELER_TYPE_KEY.C),
      ...getPax(infants, TRAVELER_TYPE_KEY.I),
    };
    const clonedPax = {...pax};
    Object.keys(clonedPax).forEach(
      (paxTypeKey) => (clonedPax[paxTypeKey] = {...clonedPax[paxTypeKey]})
    );
    setState((s) => ({...s, pax}));
    setError((e) => ({...e, pax: clonedPax}));
  }, [fare, history, location]);

  const onCustDetailChange = (target, custKey) => {
    const {value} = target;
    setState((s) => ({
      ...s,
      cust: {...s.cust, [custKey]: value},
    }));
    if (error.cust[custKey]) {
      setError((e) => ({...e, cust: {...e.cust, [custKey]: ''}}));
    }
  };

  const onPaxDetailChange = (e, paxTypeKey, paxKey) => {
    const {value} = e.target;
    setState((s) => ({
      ...s,
      pax: {
        ...s.pax,
        [paxTypeKey]: {...s.pax[paxTypeKey], [paxKey]: value},
      },
    }));
    if (error.pax[paxTypeKey][paxKey]) {
      setError((e) => ({
        ...e,
        pax: {...e.pax, [paxTypeKey]: {...e.pax[paxTypeKey], [paxKey]: ''}},
      }));
    }
  };

  const validateCustDetail = () => {
    const errors = {};

    const {cust} = state;
    const email = cust[CustKeys.EMAIL];
    const mobile = cust[CustKeys.MOBILE];

    if (!email) {
      errors[CustKeys.EMAIL] = 'Email is required';
    } else if (!isEmail(email)) {
      errors[CustKeys.EMAIL] = 'Invalid email';
    }

    if (!mobile) {
      errors[CustKeys.MOBILE] = 'Mobile is required';
    } else if (!isMobilePhone(mobile)) {
      errors[CustKeys.MOBILE] = 'Invalid mobile';
    }

    return errors;
  };

  const setPaxErrors = (errors, snackbarError) => {
    setError((e) => {
      const newError = {...e, pax: {...e.pax}};
      const {pax} = newError;
      Object.keys(errors).forEach((paxTypeKey) => {
        pax[paxTypeKey] = {...pax[paxTypeKey], ...errors[paxTypeKey]};
      });
      return newError;
    });
    // Set snackbar alert
    setSnackbarAlertError(snackbarError);
  };

  const handleSubmit = async () => {
    // Rest error state
    setError(INITIAL_ERROR_STATE);

    let paxKeysNotRequired = PaxKeysAlwaysNotRequired;

    if (!fare.passportRequired) {
      paxKeysNotRequired = paxKeysNotRequired.concat(PaxPassportOnlyKeys);
    }

    // First validate cust detail errors
    const custErrors = validateCustDetail();
    if (Object.keys(custErrors).length) {
      setError((e) => ({...e, cust: {...e.cust, ...custErrors}}));
      setSnackbarAlertError('Please provide the required contact details');
      return;
    }

    // Validate pax errors

    // First check for empty values only
    const {pax} = state;
    const errors = {};

    Object.keys(pax).forEach((paxTypeKey) => {
      // Filter non-required keys
      const paxObj = pax[paxTypeKey];
      Object.keys(paxObj)
        .filter((paxKey) => !paxKeysNotRequired.includes(paxKey))
        .forEach((paxKey) => {
          if (!paxObj[paxKey]) {
            errors[paxTypeKey] = {...errors[paxTypeKey], [paxKey]: 'Required'};
          }
        });
    });
    if (Object.keys(errors).length) {
      // console.log(errors);
      setPaxErrors(
        errors,
        "Some required passenger details are missing and highlighted in 'Red'. Please fix them before proceeding."
      );
      return;
    }

    // Check for specific validation.
    Object.keys(pax).forEach((paxTypeKey) => {
      const paxObj = pax[paxTypeKey];
      // console.log(paxObj);

      // 1. DOB
      const dob = tryParseDateOrNull(
        getDateFromParts(
          paxObj[PaxKeys.YEAR],
          paxObj[PaxKeys.MONTH],
          paxObj[PaxKeys.DAY]
        )
      );
      let invalid = false;
      if (!dob) {
        invalid = true;
      } else {
        // TODO: complete this validation later
        /*const ageInYears = differenceInYears(new Date(), dob);
        const trvType = getTravelerTypeFromObjKey(paxTypeKey);
        if (trvType === TRAVELER_TYPE_KEY.A) {
        }*/
      }
      if (invalid) {
        errors[paxTypeKey] = {
          ...errors[paxTypeKey],
          [PaxKeys.YEAR]: 'Invalid',
          [PaxKeys.MONTH]: 'Invalid',
          [PaxKeys.DAY]: 'Invalid',
        };
      }

      // 2. Passport related
      if (fare.passportRequired) {
        // 1. Expiry
        const expiry = tryParseDateOrNull(
          getDateFromParts(
            paxObj[PaxKeys.PASSPORT_EXPIRY_YEAR],
            paxObj[PaxKeys.PASSPORT_EXPIRY_MONTH],
            paxObj[PaxKeys.PASSPORT_EXPIRY_DAY]
          )
        );
        // TODO: Rather than isFuture, we should be making a check on the departure date.
        if (!expiry || !isFuture(expiry)) {
          errors[paxTypeKey] = {
            ...errors[paxTypeKey],
            [PaxKeys.PASSPORT_EXPIRY_YEAR]: 'Invalid',
            [PaxKeys.PASSPORT_EXPIRY_MONTH]: 'Invalid',
            [PaxKeys.PASSPORT_EXPIRY_DAY]: 'Invalid',
          };
        }

        // 2. Number
        // Can later do if (!isPassportNumber(paxObj[PaxKeys.PASSPORT_NUM], paxObj[PaxKeys.PASSPORT_COUNTRY].toUpperCase
        if (!/^[0-9a-zA-Z]+$/.test(paxObj[PaxKeys.PASSPORT_NUM])) {
          errors[paxTypeKey] = {
            ...errors[paxTypeKey],
            [PaxKeys.PASSPORT_NUM]: 'Invalid',
          };
        }
      }
    });
    if (Object.keys(errors).length) {
      setPaxErrors(
        errors,
        "Some of the given details are invalid and highlighted in 'Red'. Please fix them before proceeding."
      );
      return;
    }

    setSnackbarAlertError(null);
    setSubmitted(true);

    // proceed to pre booking
    const {cust} = state;
    const {data} = await axios.post(Endpoints.FLIGHT_PRE_BOOK, {
      fareObject: JSON.stringify(fare),
      emailSettings: {
        senderEmail: `booking@${Application.DOMAIN}`,
        senderName: Application.ORG_DISPLAY_NAME,
        senderCompany: Application.ORG,
        senderDomain: Application.DOMAIN,
        senderSupportPhone: Application.SUPPORT_PHONE,
      },
      customer: {
        email: cust[CustKeys.EMAIL],
        phone: cust[CustKeys.MOBILE],
        utcOffsetInMinutes: new Date().getTimezoneOffset(),
      },
      passengers: Object.keys(pax).map((paxTypeKey) => {
        const paxObj = pax[paxTypeKey];
        let paxPayload = {
          type: getTravelerTypeFromObjKey(paxTypeKey).toUpperCase(),
          title: paxObj[PaxKeys.TITLE],
          firstName: paxObj[PaxKeys.FNAME],
          middleName: paxObj[PaxKeys.MNAME],
          lastName: paxObj[PaxKeys.LNAME],
          dob: dateToDateOnlyISO(
            parseDate(
              getDateFromParts(
                paxObj[PaxKeys.YEAR],
                paxObj[PaxKeys.MONTH],
                paxObj[PaxKeys.DAY]
              )
            )
          ),
          gender: paxObj[PaxKeys.GENDER],
        };
        if (fare.passportRequired) {
          paxPayload.passportIssuanceCountry = paxObj[PaxKeys.PASSPORT_COUNTRY].toUpperCase();
          paxPayload.passportExpiryDate = dateToDateOnlyISO(
            parseDate(
              getDateFromParts(
                paxObj[PaxKeys.PASSPORT_EXPIRY_YEAR],
                paxObj[PaxKeys.PASSPORT_EXPIRY_MONTH],
                paxObj[PaxKeys.PASSPORT_EXPIRY_DAY]
              )
            )
          );
          paxPayload.passportNumber = paxObj[PaxKeys.PASSPORT_NUM];
        }
        return paxPayload;
      }),
    });
    history.push(
      getLocation(PageUrl.PAYMENT, null, {
        fare,
        sessionId: location.state.sessionId,
        location,
        bookingId: data.bookingId,
      })
    );
  };

  if (!fare) {
    return null;
  }

  return (
    <>
      <div id="main-wrapper">
        <Helmet title={composePageTitle('Booking')} />
        <Header />
        <section className="page-header page-header-text-light bg-secondary">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h1>Flights - Confirm Details</h1>
              </div>
              <div className="col-md-4">
                <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                  <li>
                    <RouterLink to={getHomeLocation(location)}>Home</RouterLink>
                  </li>
                  <li>
                    <RouterLink to={location.state.location}>
                      Flights
                    </RouterLink>
                  </li>
                  <li className="active">Flights Confirm Details</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div id="content">
          <section className="container">
            <div className="row pt-3">
              <div className="col-lg-4 pb-3">
                <div className="bg-light shadow-md rounded p-3">
                  <h4 className="mb-3" style={{fontSize: '1.5em'}}>
                    <b>Fare Details</b>
                  </h4>
                  <ul className="list-unstyled mt-4">
                    <li className="mb-2">
                      Base Fare{' '}
                      <span className="float-right text-4 font-weight-500 text-dark">
                        {roundFormatMoney(fare.baseFare)}
                      </span>
                      <br />
                      <small className="text-muted">
                        Adult : {fare.pax.adults}, Child : {fare.pax.children},
                        Infant : {fare.pax.infants}
                      </small>{' '}
                    </li>
                    <li className="mb-2">
                      Taxes & Fees{' '}
                      <span className="float-right text-4 font-weight-500 text-dark">
                        {roundFormatMoney(fare.totalTax)}
                      </span>{' '}
                    </li>
                  </ul>
                  <div className="text-dark bg-light-4 text-4 font-weight-600 p-3 PAD">
                    {' '}
                    Total Amount{' '}
                    <span className="float-right text-6">
                      {roundFormatMoney(fare.baseFare + fare.totalTax)}
                    </span>{' '}
                  </div>
                </div>
              </div>
              <div className="col-lg-8 pl-lg-0">
                <div className="bg-light rounded p-3 confirm-details">
                  <h4 className="mb-4" style={{fontSize: '1.5em'}}>
                    <b>Confirm Flight Details</b>
                  </h4>
                  <FlightOverview sessionId={location.state.sessionId} fare={fare}></FlightOverview>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div style={{backgroundColor: '#f6f7f8'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12" style={{marginBottom: '40px'}}>
                <div className="bg-light rounded p-3 p-sm-4 confirm-details">
                  <div className="alert alert-info">
                    {' '}
                    <span className="badge badge-info">NOTE:</span> This is a
                    special fare given by the airline. Airlines cancellation
                    charges do apply.{' '}
                  </div>
                  <h4 className="mb-3 mt-4" style={{fontSize: '1.5em'}}>
                    <b>Traveller Details</b>
                  </h4>
                  <p className="font-weight-600">Contact Details</p>
                  <div className="form-row">
                    <div className="col-sm-6 form-group">
                      <TextField
                        name="email"
                        id="email"
                        placeholder="Enter Email"
                        variant="outlined"
                        margin="none"
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          classes: {input: classes.textField},
                          inputProps: {tabIndex: '0'},
                        }}
                        value={state.cust[CustKeys.EMAIL]}
                        onChange={(e) =>
                          onCustDetailChange(e.target, CustKeys.EMAIL)
                        }
                        error={Boolean(error.cust[CustKeys.EMAIL])}
                        helperText={error.cust[CustKeys.EMAIL] ?? ''}
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <TextField
                        name="mobile"
                        id="mobile"
                        placeholder="Enter Mobile Number"
                        variant="outlined"
                        margin="none"
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          classes: {input: classes.textField},
                          inputProps: {tabIndex: '0'},
                        }}
                        value={state.cust[CustKeys.MOBILE]}
                        onChange={(e) =>
                          onCustDetailChange(e.target, CustKeys.MOBILE)
                        }
                        error={Boolean(error.cust[CustKeys.MOBILE])}
                        helperText={error.cust[CustKeys.MOBILE] ?? ''}
                        type="tel"
                      />
                    </div>
                  </div>
                  <p className="text-info">
                    Your booking details will be sent to this email address.
                  </p>
                  {state.pax && error.pax
                    ? Object.keys(state.pax).map((p, index) => {
                        const trv = state.pax[p];
                        const trvError = error.pax[p];
                        const trvType = getTravelerTypeFromObjKey(p);
                        const currYear = new Date().getFullYear();
                        return (
                          <React.Fragment key={index}>
                            <p className="font-weight-600">
                              {getTravelerLabelFromObjKey(p)}
                            </p>
                            <div className="form-row">
                              <div className="col-sm-1 form-group">
                                <label>Title*</label>
                                <select
                                  className={clsx(
                                    'custom-select',
                                    Boolean(trvError[PaxKeys.TITLE]) && 'error'
                                  )}
                                  id="title"
                                  value={trv[PaxKeys.TITLE]}
                                  onChange={(e) =>
                                    onPaxDetailChange(e, p, PaxKeys.TITLE)
                                  }>
                                  <option value="">Title</option>
                                  {getTravelerTitleOptions(trvType)}
                                </select>
                              </div>
                              <div className="col-12 col-sm-3 col-lg-2 form-group ">
                                <label>First Name*</label>
                                <input
                                  className={clsx(
                                    'form-control',
                                    Boolean(trvError[PaxKeys.FNAME]) && 'error'
                                  )}
                                  id="firstName"
                                  placeholder="Enter First Name"
                                  type="text"
                                  value={trv[PaxKeys.FNAME]}
                                  onChange={(e) =>
                                    onPaxDetailChange(e, p, PaxKeys.FNAME)
                                  }
                                />
                              </div>
                              <div className="col-6 col-sm-3 col-lg-2 form-group">
                                <label>Middle Name</label>
                                <input
                                  className="form-control"
                                  id="middleName"
                                  placeholder="Enter Middle Name"
                                  type="text"
                                  value={trv[PaxKeys.MNAME]}
                                  onChange={(e) =>
                                    onPaxDetailChange(e, p, PaxKeys.MNAME)
                                  }
                                />
                              </div>
                              <div className="col-6 col-sm-3 col-lg-2 form-group">
                                <label>Last Name*</label>
                                <input
                                  className={clsx(
                                    'form-control',
                                    Boolean(trvError[PaxKeys.LNAME]) && 'error'
                                  )}
                                  data-bv-field="number"
                                  id="lastName"
                                  placeholder="Enter Last Name"
                                  type="text"
                                  value={trv[PaxKeys.LNAME]}
                                  onChange={(e) =>
                                    onPaxDetailChange(e, p, PaxKeys.LNAME)
                                  }
                                />
                              </div>
                              <div className="col-sm-3 form-group">
                                <label>
                                  D.O.B* (must be{' '}
                                  {trvType === TRAVELER_TYPE_KEY.A
                                    ? '12+'
                                    : trvType === TRAVELER_TYPE_KEY.C
                                    ? '2 - 11'
                                    : '0 - 2'}{' '}
                                  yrs old)
                                </label>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexBasis: '',
                                  }}>
                                  <select
                                    className={clsx(
                                      'custom-select',
                                      Boolean(trvError[PaxKeys.MONTH]) &&
                                        'error'
                                    )}
                                    id="Month"
                                    style={{
                                      marginRight: '4px',
                                    }}
                                    onChange={(e) =>
                                      onPaxDetailChange(e, p, PaxKeys.MONTH)
                                    }
                                    value={trv[PaxKeys.MONTH]}>
                                    <option value="">Month</option>
                                    {Months.map((m, i) => (
                                      <option value={i + 1} key={i}>
                                        {m}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    className={clsx(
                                      'custom-select',
                                      Boolean(trvError[PaxKeys.DAY]) && 'error'
                                    )}
                                    style={{width: '70%'}}
                                    id="Day"
                                    onChange={(e) =>
                                      onPaxDetailChange(e, p, PaxKeys.DAY)
                                    }
                                    value={trv[PaxKeys.DAY]}>
                                    <option value="">Day</option>
                                    {range(1, 32).map((i) => (
                                      <option value={i} key={i}>
                                        {i}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    className={clsx(
                                      'custom-select',
                                      Boolean(trvError[PaxKeys.YEAR]) && 'error'
                                    )}
                                    style={{
                                      marginLeft: '4px',
                                    }}
                                    id="Year"
                                    onChange={(e) =>
                                      onPaxDetailChange(e, p, PaxKeys.YEAR)
                                    }
                                    value={trv[PaxKeys.YEAR]}>
                                    <option value="">Year</option>
                                    {range(currYear, currYear - 121, -1).map(
                                      (i) => (
                                        <option value={i} key={i}>
                                          {i}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-2 form-group">
                                <label>Gender*</label>
                                <select
                                  className={clsx(
                                    'custom-select',
                                    Boolean(trvError[PaxKeys.GENDER]) && 'error'
                                  )}
                                  id="gender"
                                  onChange={(e) =>
                                    onPaxDetailChange(e, p, PaxKeys.GENDER)
                                  }
                                  value={trv[PaxKeys.GENDER]}>
                                  <option value="">Gender</option>
                                  <option value="M">Male</option>
                                  <option value="F">Female</option>
                                </select>
                              </div>
                              {fare.passportRequired ? (
                                <>
                                  <div className="col-sm-2 form-group">
                                    <label>Passport Issuer Country*</label>
                                    <CountrySelect
                                      className={clsx(
                                        'custom-select',
                                        Boolean(
                                          trvError[PaxKeys.PASSPORT_COUNTRY]
                                        ) && 'error'
                                      )}
                                      onChange={(e) =>
                                        onPaxDetailChange(
                                          e,
                                          p,
                                          PaxKeys.PASSPORT_COUNTRY
                                        )
                                      }
                                      value={trv[PaxKeys.PASSPORT_COUNTRY]}
                                      required={false}
                                    />
                                  </div>
                                  <div className="col-sm-3 form-group">
                                    <label>Passport Expiry*</label>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexBasis: '',
                                      }}>
                                      <select
                                        className={clsx(
                                          'custom-select',
                                          Boolean(
                                            trvError[
                                              PaxKeys.PASSPORT_EXPIRY_MONTH
                                            ]
                                          ) && 'error'
                                        )}
                                        id="passportExpiryMonth"
                                        style={{
                                          marginRight: '4px',
                                        }}
                                        onChange={(e) =>
                                          onPaxDetailChange(
                                            e,
                                            p,
                                            PaxKeys.PASSPORT_EXPIRY_MONTH
                                          )
                                        }
                                        value={
                                          trv[PaxKeys.PASSPORT_EXPIRY_MONTH]
                                        }>
                                        <option value="">Month</option>
                                        {Months.map((m, i) => (
                                          <option value={i + 1} key={i}>
                                            {m}
                                          </option>
                                        ))}
                                      </select>
                                      <select
                                        className={clsx(
                                          'custom-select',
                                          Boolean(
                                            trvError[
                                              PaxKeys.PASSPORT_EXPIRY_DAY
                                            ]
                                          ) && 'error'
                                        )}
                                        style={{width: '70%'}}
                                        id="passportExpiryDay"
                                        onChange={(e) =>
                                          onPaxDetailChange(
                                            e,
                                            p,
                                            PaxKeys.PASSPORT_EXPIRY_DAY
                                          )
                                        }
                                        value={
                                          trv[PaxKeys.PASSPORT_EXPIRY_DAY]
                                        }>
                                        <option value="">Day</option>
                                        {range(1, 32).map((i) => (
                                          <option value={i} key={i}>
                                            {i}
                                          </option>
                                        ))}
                                      </select>
                                      <select
                                        className={clsx(
                                          'custom-select',
                                          Boolean(
                                            trvError[
                                              PaxKeys.PASSPORT_EXPIRY_YEAR
                                            ]
                                          ) && 'error'
                                        )}
                                        style={{
                                          marginLeft: '4px',
                                        }}
                                        id="passportExpiryYear"
                                        onChange={(e) =>
                                          onPaxDetailChange(
                                            e,
                                            p,
                                            PaxKeys.PASSPORT_EXPIRY_YEAR
                                          )
                                        }
                                        value={
                                          trv[PaxKeys.PASSPORT_EXPIRY_YEAR]
                                        }>
                                        <option value="">Year</option>
                                        {range(currYear, currYear + 100, 1).map(
                                          (i) => (
                                            <option value={i} key={i}>
                                              {i}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-3 col-lg-2 form-group ">
                                    <label>Passport Number*</label>
                                    <input
                                      className={clsx(
                                        'form-control',
                                        Boolean(trvError[PaxKeys.PASSPORT_NUM]) &&
                                          'error'
                                      )}
                                      id="passportNo"
                                      placeholder="Enter Passport Number"
                                      type="text"
                                      value={trv[PaxKeys.PASSPORT_NUM]}
                                      onChange={(e) =>
                                        onPaxDetailChange(e, p, PaxKeys.PASSPORT_NUM)
                                      }
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </React.Fragment>
                        );
                      })
                    : null}
                </div>
                <button
                  style={{borderRadius: '0px'}}
                  className="btn btn-primary btn-block"
                  onClick={handleSubmit}
                  disabled={submitted}>
                  Proceed To Payment
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {snackbarAlert}
    </>
  );
};

export default Booking;
