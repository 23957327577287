import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ButtonBase from '@material-ui/core/ButtonBase';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popover from '@material-ui/core/Popover';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useSnackbarAlert from '../hooks/useSnackbarAlert';
import {
  FlightClass,
  JourneyType,
  ListingQParams,
  MATH_OPERATION,
  PageUrl,
  PAX_LIMIT,
  QueryKeys,
  TravelerType,
  TravelerTypeToAge,
} from '../Constants';
import queryString from 'query-string';
import {useQuery} from 'react-query';
import {useHistory, useLocation} from 'react-router-dom';
import {airportSearchFetch, airportsFetch} from '../api/fetches';
import {Popper} from '@material-ui/core';
import {Calendar} from 'react-date-range';
import {formatDate, tryParseDateOrNull, tryParseNumOr0} from '../Util';
import {isBefore} from 'date-fns';
import {
  getLocation,
  tryGetAirport,
  updateMultipleInSearchQuery,
} from '../Common';
import {QS_DATE_FORMAT} from '../Constants';
import clsx from 'clsx';

const DISPLAY_DATE_FORMAT = 'MMM dd, yyyy';

const PopoverItem = {
  DEPART_DATE: 'DepartDate',
  RETURN_DATE: 'ReturnDate',
  TRAVELERS: 'Travelers',
};

const useStyles = makeStyles((theme) => ({
  popper: {},
  textField: {
    fontSize: '1rem',
  },
  buttonBaseRoot: {
    width: '100%',
  },
  radioLabelRoot: {
    marginBottom: 0,
  },
  radioLabelLabel: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    color: 'inherit',
    lineHeight: 'inherit',
  },
  radioRoot: {
    padding: '2px',
  },
  listingSearchButton: {
    padding: theme.spacing(1),
  },
}));

const ContainerPopover = ({id, anchorEl, open, onClose, classes, children}) => (
  <Popover
    id={id}
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
    getContentAnchorEl={null}
    classes={classes}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}>
    {children}
  </Popover>
);

ContainerPopover.propTypes = {
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  children: PropTypes.node.isRequired,
};

ContainerPopover.defaultProps = {
  anchorEl: null,
  classes: {},
};

function Anchor(el = null, popoverItem = null) {
  this.el = el;
  this.popoverItem = popoverItem;
}

// https://github.com/mui/material-ui/issues/19376#issuecomment-602712464
const CustomPopper = (props) => (
  <Popper {...props} style={{width: 300}} placement="bottom-start" />
);

const StateKeys = {
  ROUND_TRIP: 'roundTrip',
  FROM: 'from',
  TO: 'to',
  DEP_DATE: 'departDate',
  RET_DATE: 'returnDate',
  ADULTS: 'adults',
  CHILDREN: 'children',
  INFANTS: 'infants',
  FLIGHT_CLASS: 'cls',
  POPOVER_ANCHOR: 'popoverAnchor',
  TRAVELERS: 'travelers',
};

const ErrorKeys = {
  TRAVELER: 'traveler',
};

const ValidationStateKeys = [
  StateKeys.FROM,
  StateKeys.TO,
  StateKeys.DEP_DATE,
  StateKeys.RET_DATE,
];

const ListingSearch = React.memo(({searchText, showSearch}) => {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const {data: airports} = useQuery([QueryKeys.AIRPORTS], airportsFetch, {
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const parsed = useMemo(() => queryString.parse(location.search), [
    location.search,
  ]);
  const depDateInputRef = useRef(null);
  const retDateInputRef = useRef(null);
  const travelersRef = useRef(null);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isListing = location.pathname === PageUrl.LISTING;

  const jtype = parsed[ListingQParams.JOURNEY_TYPE];
  const from = parsed[ListingQParams.FROM];
  const to = parsed[ListingQParams.TO];
  const dep = parsed[ListingQParams.DEPARTURE_DATE];
  const ret = parsed[ListingQParams.RETURN_DATE];
  const adults = parsed[ListingQParams.ADULTS];
  const children = parsed[ListingQParams.CHILDREN];
  const infants = parsed[ListingQParams.INFANTS];
  const cls = parsed[ListingQParams.CLASS];

  const [state, setState] = useState({
    [StateKeys.ROUND_TRIP]: jtype ? jtype === JourneyType.RETURN : true,
    [StateKeys.FROM]: from,
    [StateKeys.TO]: to,
    [StateKeys.DEP_DATE]: tryParseDateOrNull(dep),
    [StateKeys.RET_DATE]: tryParseDateOrNull(ret),
    [StateKeys.ADULTS]: tryParseNumOr0(adults) || 1,
    [StateKeys.CHILDREN]: tryParseNumOr0(children),
    [StateKeys.INFANTS]: tryParseNumOr0(infants),
    [StateKeys.FLIGHT_CLASS]: Object.values(FlightClass).includes(cls)
      ? cls
      : FlightClass.ECONOMY,
    [StateKeys.POPOVER_ANCHOR]: new Anchor(),
  });

  const {
    data: fromResult,
    isLoading: fromLoading,
    isFetching: fromFetching,
  } = useQuery(
    [QueryKeys.AIRPORT_SEARCH_FROM, state[StateKeys.FROM]],
    airportSearchFetch,
    {refetchOnWindowFocus: false}
  );
  const {
    data: toResult,
    isLoading: toLoading,
    isFetching: toFetching,
  } = useQuery(
    [QueryKeys.AIRPORT_SEARCH_TO, state[StateKeys.TO]],
    airportSearchFetch,
    {
      refetchOnWindowFocus: false,
    }
  );

  const [error, setError] = useState({
    [StateKeys.FROM]: null,
    [StateKeys.TO]: null,
    [StateKeys.DEP_DATE]: null,
    [StateKeys.RET_DATE]: null,
    [ErrorKeys.TRAVELER]: null,
  });

  const [, snackbarAlert, setSnackbarAlertError] = useSnackbarAlert();

  const onRoundTripChange = () => {
    setState((s) => ({
      ...s,
      roundTrip: !s.roundTrip,
    }));
  };

  const getLocationInputValue = (option) => {
    if (typeof option === 'string') {
      return option.trim();
    } else if (option && option.iata) {
      return option.iata;
    } else {
      return option;
    }
  };

  const resetError = (stateKey) => {
    if (error[stateKey]) {
      setError({...error, [stateKey]: null});
    }
  };

  const onFromToChange = (key, value) => {
    resetError(key);
    const newValue = getLocationInputValue(value);
    setState((s) => ({
      ...s,
      [key]: newValue?.toUpperCase(),
    }));
  };

  const onFromChange = (e, value) => {
    onFromToChange(StateKeys.FROM, value);
  };

  const onToChange = (e, value) => {
    onFromToChange(StateKeys.TO, value);
  };

  const triggerPopover = (ref, popoverItemType) => {
    setState((s) => ({
      ...s,
      [StateKeys.POPOVER_ANCHOR]: new Anchor(ref.current, popoverItemType),
    }));
  };

  const onPopoverClose = () => {
    setState((s) => ({
      ...s,
      [StateKeys.POPOVER_ANCHOR]: new Anchor(),
    }));
  };

  const onDepDateChange = (date) => {
    resetError(StateKeys.DEP_DATE);
    setState((s) => ({
      ...s,
      [StateKeys.DEP_DATE]: date,
      [StateKeys.RET_DATE]:
        s[StateKeys.RET_DATE] && isBefore(s[StateKeys.RET_DATE], date)
          ? date
          : s[StateKeys.RET_DATE],
      [StateKeys.POPOVER_ANCHOR]: s[StateKeys.ROUND_TRIP]
        ? new Anchor(retDateInputRef.current, PopoverItem.RETURN_DATE)
        : new Anchor(),
    }));
  };

  const onRetDateChange = (date) => {
    resetError(StateKeys.RET_DATE);
    setState((s) => ({
      ...s,
      [StateKeys.RET_DATE]: date,
      [StateKeys.POPOVER_ANCHOR]: new Anchor(),
    }));
  };

  const getTravelerStateKey = (type) => {
    switch (type) {
      case TravelerType.ADULTS:
        return StateKeys.ADULTS;
      case TravelerType.CHILDREN:
        return StateKeys.CHILDREN;
      case TravelerType.INFANTS:
        return StateKeys.INFANTS;
      default:
        throw new Error(`Unrecognized traveler: ${type}`);
    }
  };

  const getTotalPax = () => {
    return (
      state[StateKeys.ADULTS] +
      state[StateKeys.CHILDREN] +
      state[StateKeys.INFANTS]
    );
  };

  const showTravelerLimitError = () => {
    setSnackbarAlertError(`Maximum ${PAX_LIMIT} travelers please.`);
  };

  const onTravelerChange = (type, operation) => {
    if (error[ErrorKeys.TRAVELER]) {
      setError((e) => ({...e, [ErrorKeys.TRAVELER]: null}));
    }
    if (operation === MATH_OPERATION.PLUS && getTotalPax() > PAX_LIMIT) {
      showTravelerLimitError();
      return;
    }
    setState((s) => {
      const stateKey = getTravelerStateKey(type);
      let currVal = s[stateKey];

      if (currVal === 0 && operation === MATH_OPERATION.MINUS) {
        return s;
      }

      if (
        type === TravelerType.ADULTS &&
        currVal === 1 &&
        operation === MATH_OPERATION.MINUS
      ) {
        return s;
      }

      if (operation === MATH_OPERATION.PLUS) {
        currVal++;
      } else {
        currVal--;
      }

      return {...s, [stateKey]: currVal};
    });
  };

  const validateOnSubmit = (keysSkipValidate = []) => {
    const errors = {};

    ValidationStateKeys.filter((k) => !keysSkipValidate.includes(k)).forEach(
      (sk) => {
        let value = state[sk];
        if (typeof value === 'string') {
          value = value.trim();
        }
        if (!value) {
          errors[sk] = 'Required';
        } else if (sk === StateKeys.FROM || sk === StateKeys.TO) {
          if (!tryGetAirport(airports, value)) {
            errors[sk] = 'Invalid code';
          }
        }
      }
    );

    return errors;
  };

  const onSearchClick = () => {
    const errors = validateOnSubmit(
      !state[StateKeys.ROUND_TRIP] ? [StateKeys.RET_DATE] : []
    );
    if (Object.keys(errors).length > 0) {
      setError({...error, ...errors});
      return;
    }

    // Validate specific fields
    if (getTotalPax() > PAX_LIMIT) {
      setError((e) => ({...e, [ErrorKeys.TRAVELER]: 'Invalid'}));
      showTravelerLimitError();
      return;
    }

    setSnackbarAlertError(null);

    updateMultipleInSearchQuery(
      getLocation(PageUrl.LISTING, location.search, location.state),
      history,
      {
        [ListingQParams.JOURNEY_TYPE]: state[StateKeys.ROUND_TRIP]
          ? JourneyType.RETURN
          : JourneyType.ONE_WAY,
        [ListingQParams.FROM]: state[StateKeys.FROM],
        [ListingQParams.TO]: state[StateKeys.TO],
        [ListingQParams.DEPARTURE_DATE]: formatDate(
          state[StateKeys.DEP_DATE],
          QS_DATE_FORMAT
        ),
        [ListingQParams.RETURN_DATE]: state[StateKeys.ROUND_TRIP]
          ? formatDate(state[StateKeys.RET_DATE], QS_DATE_FORMAT)
          : null,
        [ListingQParams.ADULTS]: state[StateKeys.ADULTS],
        [ListingQParams.CHILDREN]: state[StateKeys.CHILDREN],
        [ListingQParams.INFANTS]: state[StateKeys.INFANTS],
        [ListingQParams.CLASS]: state[StateKeys.FLIGHT_CLASS],
      }
    );
  };

  const getMainContent = () => (
    <div className="d-flex justify-content-between flex-column flex-xl-row flex-1">
      <div className="d-flex flex-column flex-md-row position-relative flex1 border-light">
        <div className="search-control from-lists position-relative">
          <div className="d-flex form-control flightList">
            <span>
              <i className="fa fa-plane-departure" />
            </span>
            <Autocomplete
              freeSolo
              options={fromResult || []}
              getOptionLabel={getLocationInputValue}
              renderOption={(option) =>
                `${option.iata ? option.iata + ' - ' : ''}${option.name}`
              }
              onChange={onFromChange}
              onInputChange={onFromChange}
              value={state[StateKeys.FROM] || ''}
              loading={fromLoading || fromFetching}
              fullWidth
              classes={{popper: classes.popper, input: classes.textField}}
              placement="bottom"
              PopperComponent={CustomPopper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="From"
                  margin="none"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    style: {padding: theme.spacing(1, 1.5)},
                  }}
                  {...{autoFocus: !isListing}}
                  variant="standard"
                  error={Boolean(error[StateKeys.FROM])}
                  helperText={error[StateKeys.FROM] ?? ''}
                />
              )}
            />
          </div>
        </div>
        <div className="search-control to-lists position-relative bdr-light-left">
          <div className="d-flex form-control flightList">
            <span className="ml-3">
              <i className="fa fa-plane-arrival" />
            </span>
            <Autocomplete
              freeSolo
              options={toResult || []}
              getOptionLabel={getLocationInputValue}
              renderOption={(option) =>
                `${option.iata ? option.iata + ' - ' : ''}${option.name}`
              }
              onChange={onToChange}
              onInputChange={onToChange}
              value={state[StateKeys.TO] || ''}
              loading={toLoading || toFetching}
              fullWidth
              classes={{popper: classes.popper, input: classes.textField}}
              placement="bottom"
              PopperComponent={CustomPopper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="To"
                  margin="none"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    style: {padding: theme.spacing(1, 1.5)},
                  }}
                  variant="standard"
                  error={Boolean(error[StateKeys.TO])}
                  helperText={error[StateKeys.TO] ?? ''}
                />
              )}
            />
          </div>
        </div>
        <button className="btn btn-light btn-swap" type="button">
          <i className="fa fa-exchange-alt" />
        </button>
      </div>
      <div className="d-flex flex-column flex-md-row dat-selector flex1 border-light">
        <div className="position-relative search-control depart-on">
          <div className="d-flex form-control pr-0">
            <span>
              <i className="far fa-calendar-alt" />
            </span>
            <TextField
              placeholder="Departure"
              margin="none"
              fullWidth
              InputProps={{
                disableUnderline: true,
                style: {padding: theme.spacing(1, 1.5)},
              }}
              variant="standard"
              ref={depDateInputRef}
              onClick={() =>
                triggerPopover(depDateInputRef, PopoverItem.DEPART_DATE)
              }
              value={
                state[StateKeys.DEP_DATE]
                  ? formatDate(state[StateKeys.DEP_DATE], DISPLAY_DATE_FORMAT)
                  : ''
              }
              error={Boolean(error[StateKeys.DEP_DATE])}
              helperText={error[StateKeys.DEP_DATE] ?? ''}
            />
            <ContainerPopover
              id="departDatePopover"
              anchorEl={state[StateKeys.POPOVER_ANCHOR].el}
              open={
                state[StateKeys.POPOVER_ANCHOR].popoverItem ===
                PopoverItem.DEPART_DATE
              }
              onClose={onPopoverClose}>
              <Calendar
                onChange={onDepDateChange}
                date={state[StateKeys.DEP_DATE]}
                months={2}
                direction={isDesktop ? 'horizontal' : 'vertical'}
                minDate={new Date()}
              />
            </ContainerPopover>
          </div>
        </div>
        {state[StateKeys.ROUND_TRIP] ? (
          <div
            className="position-relative search-control bdr-light-left"
            id="returnOn">
            <div className="d-flex form-control pr-0">
              <span>
                <i className="far fa-calendar-alt" aria-hidden="true"></i>
              </span>
              <TextField
                placeholder="Return"
                margin="none"
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  style: {padding: theme.spacing(1, 1.5)},
                }}
                variant="standard"
                ref={retDateInputRef}
                onClick={() =>
                  triggerPopover(retDateInputRef, PopoverItem.RETURN_DATE)
                }
                value={
                  state[StateKeys.RET_DATE]
                    ? formatDate(state[StateKeys.RET_DATE], DISPLAY_DATE_FORMAT)
                    : ''
                }
                error={Boolean(error[StateKeys.RET_DATE])}
                helperText={error[StateKeys.RET_DATE] ?? ''}
              />
              <ContainerPopover
                id="returnDatePopover"
                anchorEl={state[StateKeys.POPOVER_ANCHOR].el}
                open={
                  state[StateKeys.POPOVER_ANCHOR].popoverItem ===
                  PopoverItem.RETURN_DATE
                }
                onClose={onPopoverClose}>
                <Calendar
                  onChange={onRetDateChange}
                  date={state[StateKeys.RET_DATE]}
                  months={2}
                  direction={isDesktop ? 'horizontal' : 'vertical'}
                  minDate={state[StateKeys.DEP_DATE] || new Date()}
                  shownDate={state[StateKeys.DEP_DATE] || new Date()}
                />
              </ContainerPopover>
            </div>
          </div>
        ) : null}
      </div>
      <div
        className={clsx(
          'travellers-class',
          Boolean(error[ErrorKeys.TRAVELER]) && 'error'
        )}>
        <div className="position-relative search-control">
          <ButtonBase
            disableRipple
            ref={travelersRef}
            classes={{root: classes.buttonBaseRoot}}
            onClick={() => triggerPopover(travelersRef, PopoverItem.TRAVELERS)}>
            <div
              className="form-control d-flex align-items-center border-light"
              id="flightTravellersClass"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <div className="total-members bdr-light-right pr-3 mr-3">
                <span className="mr-3">
                  <i className="fa fa-user-tie" />
                </span>
                <span className="filled-value">
                  {state[StateKeys.ADULTS] +
                    state[StateKeys.CHILDREN] +
                    state[StateKeys.INFANTS]}
                </span>
              </div>
              <div className="traveller-class">
                <span className="mr-3">
                  <i className="fa fa-chair" />
                </span>
                <span className="filled-value">
                  {state[StateKeys.FLIGHT_CLASS]}
                </span>
              </div>
            </div>
          </ButtonBase>
          <ContainerPopover
            id="travelersPopover"
            anchorEl={state[StateKeys.POPOVER_ANCHOR].el}
            open={
              state[StateKeys.POPOVER_ANCHOR].popoverItem ===
              PopoverItem.TRAVELERS
            }
            onClose={onPopoverClose}>
            <div
              className="travellers-dropdown"
              style={{position: 'relative', display: 'inherit'}}>
              <div className="search-list-heading">Travellers</div>
              <hr />
              {Object.keys(TravelerType).map((tt, index) => (
                <React.Fragment key={tt}>
                  <div className="row align-items-center">
                    <div className="col-sm-7">
                      <p className="mb-sm-0">
                        {TravelerType[tt]}{' '}
                        <small className="text-muted">
                          ({TravelerTypeToAge[tt]})
                        </small>
                      </p>
                    </div>
                    <div className="col-sm-5">
                      <div className="qty input-group">
                        <div className="input-group-prepend">
                          <button
                            type="button"
                            className="btn bg-light-4"
                            data-value="decrease"
                            data-target="#flightAdult-travellers"
                            data-toggle="spinner"
                            onClick={() =>
                              onTravelerChange(
                                TravelerType[tt],
                                MATH_OPERATION.MINUS
                              )
                            }>
                            -
                          </button>
                        </div>
                        <input
                          type="text"
                          data-ride="spinner"
                          id="flightAdult-travellers"
                          className="qty-spinner form-control"
                          value={state[getTravelerStateKey(TravelerType[tt])]}
                          readOnly
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn bg-light-4"
                            data-value="increase"
                            data-target="#flightAdult-travellers"
                            data-toggle="spinner"
                            onClick={() =>
                              onTravelerChange(
                                TravelerType[tt],
                                MATH_OPERATION.PLUS
                              )
                            }>
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {index < Object.keys(TravelerType).length - 1 ? (
                    <hr className="mt-2" />
                  ) : null}
                </React.Fragment>
              ))}
              <hr className="mt-2" />
              <div className="search-list-heading">Classes</div>
              <hr />
              <div className="mb-3">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={state[StateKeys.FLIGHT_CLASS]}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        [StateKeys.FLIGHT_CLASS]: e.target.value,
                      }))
                    }>
                    {Object.keys(FlightClass).map((fcls) => (
                      <FormControlLabel
                        classes={{
                          root: classes.radioLabelRoot,
                          label: classes.radioLabelLabel,
                        }}
                        value={FlightClass[fcls]}
                        control={
                          <Radio
                            color="primary"
                            classes={{root: classes.radioRoot}}
                          />
                        }
                        label={FlightClass[fcls]}
                        key={fcls}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
              <button
                className="btn btn-primary btn-block submit-done"
                type="button"
                onClick={onPopoverClose}>
                Done
              </button>
            </div>
          </ContainerPopover>
        </div>
      </div>

      <button
        className={clsx(
          'btn btn-primary',
          isListing ? 'research-flight mt-3 mt-lg-0' : 'search-flight2',
          isListing && classes.listingSearchButton
        )}
        type="button"
        onClick={onSearchClick}>
        <i className="fa fa-search mr-2" /> {searchText}
      </button>
    </div>
  );

  return (
    <>
      <div
        id="searchFlight"
        className={clsx(
          isListing && 'mb-4',
          isListing && !showSearch && 'show-on-mobile'
        )}>
        <label className="switch">
          <input
            type="checkbox"
            onChange={onRoundTripChange}
            checked={state[StateKeys.ROUND_TRIP]}
          />
          <span className="slider round" />
          <span className="labeled" />
        </label>
        {!isListing ? (
          <div className="search-section glass-bg">{getMainContent()}</div>
        ) : (
          getMainContent()
        )}
      </div>
      {snackbarAlert}
    </>
  );
});

ListingSearch.propTypes = {
  searchText: PropTypes.string.isRequired,
  showSearch: PropTypes.bool,
};

ListingQParams.defaultProps = {
  showSearch: false,
};

export default ListingSearch;
