import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useHistory, useLocation, Link as RouterLink} from 'react-router-dom';
import {composePageTitle, getHomeLocation, getLocation} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import {PageUrl} from './Constants';
import {formatDate} from './Util';

const Thankyou = () => {
  const history = useHistory();
  const location = useLocation();

  const bookingResult = location.state?.bookingResult;
  const fare = location.state?.fare;
  const customerCard = location.state?.customerCard;

  useEffect(() => {
    if (!(bookingResult && customerCard && fare)) {
      history.push(getLocation(PageUrl.HOME));
    }
  }, [bookingResult, customerCard, history, fare]);

  if (!(bookingResult && customerCard && fare)) {
    return;
  }

  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('Thank you')} />
      <Header />
      <section className="page-header page-header-text-light bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Flight Confirmation</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={getHomeLocation(location)}>Home</RouterLink>
                </li>
                <li class="active">Flight Confirmation Details</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="row mt-4 mb-3">
            <div className="col-lg-11 mx-auto">
              <div className="row widget-steps">
                <div className="col-3 step complete">
                  <div className="step-name">Flights</div>
                  <div className="progress">
                    <div className="progress-bar"></div>
                  </div>
                  <div class="step-dot"></div>
                </div>
                <div className="col-3 step complete">
                  <div className="step-name">Booking</div>
                  <div className="progress">
                    <div className="progress-bar"></div>
                  </div>
                  <div class="step-dot"></div>
                </div>
                <div className="col-3 step complete">
                  <div className="step-name">Payment</div>
                  <div className="progress">
                    <div className="progress-bar"></div>
                  </div>
                  <div class="step-dot"></div>
                </div>
                <div className="col-3 step complete">
                  <div className="step-name">Success</div>
                  <div className="progress">
                    <div className="progress-bar"></div>
                  </div>
                  <div class="step-dot"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mb-5">
            <div className="row">
              <table
                className="contenttable"
                style={{
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderCollapse: 'separate',
                  borderColor: '#ededed',
                  marginTop: '20px',
                  fontFamily: 'Arial, Helvetica, sans-serif',
                  width: '-moz-available',
                }}
                width="100%"
                cellSpacing="0"
                cellPadding="0"
                border="0"
                bgcolor="#ffffff">
                <tbody>
                  <tr>
                    <td style={{padding: '0px 20px'}}>
                      <table
                        width="100%"
                        cellSpacing="0"
                        cellPadding="0"
                        border="0">
                        <tbody>
                          <div className=" mt-4">
                            <p className="h3"> Thank you {customerCard.name}</p>
                            <h3 style={{color: '#157512'}}>
                              Congratulations! Your flight booking is completed.{' '}
                            </h3>
                          </div>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{padding: '20px'}}>
                      <table
                        width="100%"
                        cellSpacing="0"
                        cellPadding="0"
                        border="0">
                        <tbody>
                          <tr>
                            <td style={{fontSize: '14px', lineHeight: '28px'}}>
                              <span style={{color: '#7a7a7a'}}>
                                Booking Reference Number:{' '}
                              </span>
                              {bookingResult.bookingRefNumber}
                            </td>
                          </tr>
                          <tr>
                            <td style={{fontSize: '14px', lineHeight: '28px'}}>
                              <span style={{color: '#7a7a7a'}}>
                                Booking Status:
                              </span>{' '}
                              {bookingResult.partiallyBooked ||
                              !bookingResult.success
                                ? 'In progress'
                                : 'Confirmed'}
                            </td>
                          </tr>
                          <tr>
                            <td style={{fontSize: '14px', lineHeight: '28px'}}>
                              Thank you for booking your flight tickets with us.{' '}
                              {bookingResult.chargeSuccess
                                ? 'We have successfully processed your payment.'
                                : ''}{' '}
                              A confirmation email with the booking details has
                              been sent to you (Please don't forget to check the
                              spam folder just in case). Give us a call if you
                              have any questions!
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="tablepadding"
                      style={{
                        background: '#f8f8f8',
                        borderTop: '1px solid #e9e9e9',
                        borderBottom: '1px solid #e9e9e9',
                        padding: '13px 20px',
                      }}>
                      <table
                        width="100%"
                        cellSpacing="0"
                        cellPadding="0"
                        border="0"
                        align="center">
                        <tbody>
                          <tr>
                            <td style={{fontSize: '14px', lineHeight: '20px'}}>
                              <span style={{color: '#909090'}}>
                                Booking Date:
                              </span>
                              <br />
                              <span
                                style={{
                                  color: '#000000',
                                  display: 'inline-block',
                                }}>
                                {formatDate(new Date(), 'MMM dd, yyyy')}
                              </span>
                            </td>
                            <td style={{fontSize: '14px', lineHeight: '20px'}}>
                              <span style={{color: '#909090'}}>
                                Booking Reference No:
                              </span>
                              <br />
                              {bookingResult.bookingRefNumber}
                            </td>
                            <td style={{fontSize: '14px', lineHeight: '20px'}}>
                              <span style={{color: '#909090'}}>Payment:</span>
                              <br />
                              by Credit Card
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Thankyou;
