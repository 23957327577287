import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import {init} from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {enableMapSet} from 'immer';
import axios from 'axios';
import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter as Router} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import Application from './config/application';
import './index.css';
import App from './App';
import '@fontsource/poppins';

ReactGA.initialize(Application.GA_ID, {
  gaOptions: {
    phone_conversion_number: Application.SUPPORT_PHONE
  }
});

// **Immer
// enable Map and Set ================================================
enableMapSet();

// **environment variables =======================================================
const isProduction =
  process.env.NODE_ENV === 'production' &&
  document.location.hostname !== 'localhost';

// **Sentry ======================================================================
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/
// I've added all of ignore and deny for now as it looks useful
init({
  dsn: isProduction
    ? 'https://bb0c8b3f5e484dd4b5768d63aed6f4f6@o4504259217063936.ingest.sentry.io/4504259247931392'
    : '', // disable sdk on local
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: isProduction ? 0.1 : 1.0,
  environment: process.env.NODE_ENV,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

axios.defaults.baseURL = isProduction
  ? Application.PRODUCTION_API_BASE_URL
  : Application.LOCAL_API_BASE_URL
axios.defaults.timeout = 120 * 1000;

// **React-Query =================================================================
// configure react-query
const queryClient = new QueryClient({
  defaultOptions: {
    // The staleTime, refetchOnMount, refetchOnWindowFocus should be used per query not globally.
    queries: {
      retry: 5,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
