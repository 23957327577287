import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import {PageUrl} from './Constants';

const CancelRefund = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('Cancellation & Refund Policy')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Cancellation & Refund Policy</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">Cancellation & Refund Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
            <h2 className="text-6">Policy </h2>
            <ul>
              <li>
                Tickets are Non-Refundable after 24 hours of purchase for all
                major airlines.
              </li>
              <li>
                For Low Cost Carriers, like Spirit Airlines, Frontier Airlines,
                Sun Country, etc. Refund not possible if departure date is
                within 7 days of Booking.
              </li>
              <li>
                <strong>For major airlines </strong>- tickets may be refunded if
                requested within twenty four (24) hours from the time of
                purchase at no cost, however, if the customer decides to cancel
                a ticket after 24 hours from the time of booking, a nominal
                cancellation fee will be charged and will be subjected to Refund
                or Future Credit as per Airline Policy.
              </li>
              <li>
                For low cost carriers (LCC), ticket will be non-refundable once
                booked, however, a Future Credit may be provide as per the
                Airline policy.
              </li>
              <li>
                All Cancellation are possible only before 4 hours of scheduled
                departure time.
              </li>
            </ul>

            <h2 className="text-6">Change Policy </h2>
            <ul>
              <li>
                Changes are possible as per the ticket fare rules, with an
                airline changes fees and fare difference.{' '}
              </li>
              <li>
                All Changes are possible only before 4 hours of scheduled
                departure time.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CancelRefund;
