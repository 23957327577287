const oneway = {
  sessionId: 'abxd',
  fares: [
    {
      sourceCode:
        'ZzBlaFprZlRlaVRKNzIvOGF1R3YvVHgrTnQxdHRRMURQekR5cllUQ2h5V01yMDNvZm1LNDZ5V3NxeFVab0gyZ0JyTi9Oei9QVTJGM0VIaU1vYWpVOWd5VkZPcy8zTmFISzZLSk5yQ0F1WWMwT01aS0ZHU1I0THVQMmQzMG5yeWNXSUpucTR1SnloUFVUclNlTEZrT3JRPT0=',
      directionIndicator: 'OneWay',
      baseFare: 37.21,
      totalTax: 452.59,
      perAdultTotalFare: 122.45,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T06:00:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T08:55:00',
              journeyDurationMins: 175,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T12:30:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T14:34:00',
              journeyDurationMins: 184,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'MEpPbnJpSnV6US9jUnYySnFzM3VnbTV4UzMzMVozdVJ1cFkzQzVTRzJkN3R2OHY5MnBITUlKSmpDZWt5U3VhRy9Qd2xHbkRySUM3VHdROTNUbHZRM0pJUWhTOTVDaWpuQXJWbXVXdmdSSmhTeTg0OEp0K3VkdmxHcXkyOE5RK2FYVjAzK1QrS285RUJtWmlid2JDVkxBPT0=',
      directionIndicator: 'OneWay',
      baseFare: 37.21,
      totalTax: 452.59,
      perAdultTotalFare: 122.45,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T09:05:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T12:05:00',
              journeyDurationMins: 180,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'T2FXMERXTmZmTlVXY0tCTUNNdCtEMlRZT1VsQ2RxNGQvUHNtQ0hhcDV5VUJuVHN2WWN3OC9GNm9kTFBjTUFmR3FDYTRQRGo4djhnL2RHREd0TVNKZ1JDQ20xS2hmRkVJc2gvTjY4NitnU256T1pub2IxZ2NENndmUng5OEVEUlRITkxiQVlsQ3E1OERkM3pPL3BYd3VRPT0=',
      directionIndicator: 'OneWay',
      baseFare: 53.75,
      totalTax: 453.14,
      perAdultTotalFare: 126.7225,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T10:59:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T13:56:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'RE5hb1l1Snh4SHlaNVN6R01RSk5BQWI1SXFpcE9taENXd2hnZVhYSDMxYWlZZzZibmZnZXVGTnNyeE1kdSt3YSt0RGRtaUZ3OGVwWDZhQlNvcU5taHlNU2hvM29WbFdwVXB0U2pOM250ZWFnNFNMQ3ZEVzFzRllxczdNWEowd09rSms5Qkt2bVlxdHY0Z1Z1SC8yKytnPT0=',
      directionIndicator: 'OneWay',
      baseFare: 53.75,
      totalTax: 453.14,
      perAdultTotalFare: 126.7225,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T09:29:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T12:26:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-15T17:35:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T19:38:00',
              journeyDurationMins: 183,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'ejdmblFGcXFPTEtzNWlScStGeDNMb05DeWQ4UUpoM1dyWWw5T05aNXd6ZFRpQjlnSlV5d3hzZVZoVHlSeGpXUTdvblZrbnYxMnE0NzlFeTRxRXV3eWJsZExtZzhCeEx2RFRDeDMxQ2IrY2NpV2t2U0NVQS9VR2lkNHRXK1ZQYzR1clBDaDc2RFhKWmNGSHdqMkdxYTFBPT0=',
      directionIndicator: 'OneWay',
      baseFare: 53.75,
      totalTax: 453.14,
      perAdultTotalFare: 126.7225,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T19:59:00',
              arrivalAirportLocationCode: 'MCO',
              arrivalDateTime: '2022-12-15T22:57:00',
              journeyDurationMins: 178,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'MCO',
              departureDateTime: '2022-12-16T06:00:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-16T07:57:00',
              journeyDurationMins: 177,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'L0MrRExraEloSlgwR01ITlI3allmWTlkQU5wYmpkdjM4TW9UQStlZzJjdkNJUHJ0ZFlvMDZwbmxFYlNQeWlWTTMvMU9kUUtPdzlDRE9LZTRSU1dseEpneVNsbGlvN0MxVTVlTGNGbFBqYVpPRFNyTDZnbVpJUTNhYnBuU0pxck5xQTUveVFISk9jVVIzaEc3cnFFRW5nPT0=',
      directionIndicator: 'OneWay',
      baseFare: 148.78,
      totalTax: 456.26,
      perAdultTotalFare: 151.26,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T11:35:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T14:42:00',
              journeyDurationMins: 187,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'Q0cvRHpuWFYvM2ZiWVVtQm01bHJmZ1FxbVA0R29tcXhnTU9jZ1dxUDF5S3FWSHdJS2N4Zlc0NGFuVW5oYXEvek1TaS94MHhYaW9xelNBZU5WVkd0OTB4TTNxUnlRRWljK3p4NDNQbjRVK21yKzZuSDF3MFFHQTUrS2JaRDRGRjlkdEhOa01KcUVHaTR2R0g2MmlBcWRnPT0=',
      directionIndicator: 'OneWay',
      baseFare: 219.07,
      totalTax: 458.58,
      perAdultTotalFare: 169.4125,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'LGA',
              departureDateTime: '2022-12-15T16:53:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T20:01:00',
              journeyDurationMins: 188,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
    {
      sourceCode:
        'Si91VjVyelNlc2MwMjVGMk8vRHc1VnNKKzJ4RGw3TmZmZmFWRHc1VnF2QU5nVTNKdGpLeGtDOGFaYTRlU3VMWG5TZE1yTFJjSjlwQWtyTUZWOXFpQ2ZHNDRIUFhvSEZUZUxrYm9OUnlJYWpZUjhaS3RVZFFFNmxhbUxkaUF2V0FJZjRzbWJNRVVyQUpIbkpGTmZWMHlBPT0=',
      directionIndicator: 'OneWay',
      baseFare: 458.8,
      totalTax: 466.5,
      perAdultTotalFare: 231.325,
      itineraries: [
        {
          totalStops: 1,
          stops: [
            {
              departureAirportLocationCode: 'EWR',
              departureDateTime: '2022-12-15T15:05:00',
              arrivalAirportLocationCode: 'FLL',
              arrivalDateTime: '2022-12-15T18:13:00',
              journeyDurationMins: 188,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
            {
              departureAirportLocationCode: 'FLL',
              departureDateTime: '2022-12-15T21:15:00',
              arrivalAirportLocationCode: 'ORD',
              arrivalDateTime: '2022-12-15T23:40:00',
              journeyDurationMins: 205,
              cabinClassCode: 'Y',
              cabinClassText: 'STANDARD FARE',
              flightNumber: '',
              airlineCode: 'NK',
              planeCode: '3XX',
              seatsRemaining: 4,
            },
          ],
        },
      ],
      passportRequired: false,
      refundable: false,
    },
  ],
};

export default oneway;
